<template>
    <div>
        <v-text :config="textConfig" ></v-text>
    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
Vue.use(VueKonva);

export default {
    name:'Chromosome',
    data:()=>({
        height:15,
    }),
    props:{
        spec:{
            id: null,
            start: null,
            end: null
        },
        baseLineY:{
            type: Number,
            default:500
        }
        
    },
    computed:{
        textConfig(){
            const t =
             {
                x: 10,
                y: this.baseLineY + 15, 
                width: 300, 
                text: this.spec.id + "  ( " + this.spec.start + ' : ' + this.spec.end + ' )' , 
                fontSize: 15, 
                // fontStyle: 'bold',
                fill:'#999999',
                align:'left'
            }
            // console.log(t)
            return t 
        }
    }
    
}
</script>