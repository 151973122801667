<template>

  <CardMenu title="Save Filters" v-model="saveFilterMenu" tooltip="Save selected filters into a filter set" width="800px" customClass="mx-0 px-0">
    <template v-slot:button>
      <v-btn icon color="primary" > <v-icon class="mx-0 px-0">mdi-content-save</v-icon></v-btn>
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field validate-on-blur required :rules="nameRules" v-model="filterSetName" hide-details outlined dense label="Filter-Set Name"></v-text-field>
          <div class="mt-3"> 
            <span class="font-weight-medium body-2 mt-3">Cohort:</span> {{ cohortName }}
          </div>
          <div class="d-flex flex-row flex-wrap  mt-1">
            <div v-if="hasFilters" class="font-weight-medium body-2 mt-3">Selected Filters:</div>
            <div v-else class="caption red--text mt-1">* No filter is selected!</div>
            <SelectedFilterChipsList :selectedPropertiesLabels="filters.filtersLabels" dense color="grey darken-1" :enableCloseChips="false"></SelectedFilterChipsList>
          </div>
          <div class="mt-2 d-flex flex-row justify-end">
            <v-btn small width="70px" outlined color="primary" @click="onCancelFilterSet">Cancel</v-btn>
            <v-btn :disabled="!hasFilters" small width="70px" class="ml-2" color="primary" @click="onSaveFilterSet">Save</v-btn>
          </div>
        </v-form>
      </template>
  </CardMenu>

</template>
<script>
import SelectedFilterChipsList from "@/components/defaultAnalysis/filters/SelectedFilterChipsList";
import CardMenu from "@/components/Common/CardMenu.vue"
import { getCohort } from "@/api/user/dataset.js";
import {saveCohortFilterSets} from "@/api/user/user-filter-set.js"
export default {
  name: "AddFilterSet",
  components:{SelectedFilterChipsList, CardMenu},
  props: {
    filters: {
      type: Object,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    cohortName:null,
    valid: false,
    saveFilterMenu: false,
    filterSetName: null,
    nameRules: [(v) => !!v || "Filter-Set name is required", (v) => (!!v && v.length <= 200) || "Must be less than 200 characters"],
  }),
  created(){
    const _this = this
    getCohort(this.cohortId,(res)=>{_this.cohortName=res.name})

  },
  computed:{
    hasFilters(){
      return this.filters.filtersLabels!=undefined && this.filters.filtersLabels != null && this.filters.filtersLabels.length > 0 
    }
  },
  methods: {
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.filterSetName = null;
    },
    onCancelFilterSet() {
      this.reset();
      this.saveFilterMenu = false;
    },
    onSaveFilterSet() {
      if (this.$refs.form.validate()) {
        const _this = this
        saveCohortFilterSets(this.cohortId,this.filterSetName, this.filters, `${this.filterSetName} saved successfully`,()=>{
          _this.reset()
          _this.saveFilterMenu=false
        },(err) => {
          // console.log(err)
          // console.log(err.data.Reason)
          const reason = err?.data?.Reason || null
          if( reason == 'NON_FIELD_ERRORS_ALREADY_EXIST'){
            _this.$root.notify.show({message:`Filter-set name '${_this.filterSetName}' already exists!`, type:'error'})
          }
        })
      }
    },
  },
};
</script>