<template>
  <v-menu v-if="compact" right v-model="menu" open-on-click offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="comment" v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-eye-outline</v-icon>
      <v-icon v-else v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-outline</v-icon>
    </template>

    <v-card width="600px" max-height="">
      <v-card-title class="grey lighten-2 d-flex flex-row pt-1 pb-1">
        Comment
        <v-btn icon class="ml-auto" color="grey" @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-textarea class="mt-4" outlined name="input-7-1" v-model="comment" hide-details=""></v-textarea>
        <v-btn class="ml-auto mt-2" color="primary" :disabled="!comment" @click="onSave()">Save</v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
  <div v-else>
    <div class="d-flex flex-row ">
      <div class="body-2 mb-2 font-weight-medium grey--text text--darken-1 " >
      Your Comment:
    </div>
      <div class="ml-auto">
        <v-btn icon color="primary" x-small @click="isEditing = true"> <v-icon small>mdi-note-edit-outline</v-icon> </v-btn>
        <v-btn icon color="primary" x-small :disabled="!isEditing" @click="onSave()"><v-icon small >mdi-content-save-outline</v-icon></v-btn>
      </div>
    </div>
    <v-textarea class="body-2" height="100px" v-if="isEditing " outlined name="input-7-1" v-model="comment" hide-details=""></v-textarea>
    <div v-else class="body-2 viewNoteBox"> {{ comment }}</div>
  </div>
</template>
<script>
import { setProjectGeneNote } from "@/api/user/cohort.js";

export default {
  name: "CohortAnalysisGeneNote",
  props: {
    note: {
      type: String,
      default: null,
    },
    gene: {
      type: String,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    compact: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    comment: null,
    menu: false,
    isEditing: false
  }),
  created() {
    this.$eventBus.$on('onCohortAnalysisGeneNoteUpdated',(componentId, gene,note)=>{ this.onCohortAnalysisGeneNoteUpdated(componentId, gene,note)})

    this.comment = this.note;
    this.isEditing = !this.comment

  },
  watch:{
    gene(){
      this.comment = this.note;
      this.isEditing = !this.comment

    },
    note(){
      // console.log()
      this.comment = this.note;
      this.isEditing = !this.comment
    }
  },
  methods: {
    onCancel() {
      this.menu = false;
    },
    onCohortAnalysisGeneNoteUpdated(componentId, gene, comment){
      if(this._uid !== componentId && this.gene == gene){
        this.comment = comment
        console.log(this._uid, componentId, this.gene, gene, comment)
      }
    },
    onSave() {
      // const payload = {
      //   note: this.comment,
      // };
      const _this = this;
      setProjectGeneNote(this.cohortId, this.projectId, this.gene, this.comment, "Comment saved successfully ", () => {
        _this.menu = false;
        _this.isEditing = false
        _this.$eventBus.$emit('onCohortAnalysisGeneNoteUpdated',_this._uid, _this.gene,_this.comment)
      });
    },
  },
};
</script>
<style scoped lang="scss">
.viewNoteBox {
  border: 1px rgb(223, 223, 223) solid !important;
  padding: 8px ;
  min-height: 100px;
  border-radius: 6px !important;
}
</style>
