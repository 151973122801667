<template>
<v-card flat rounded="0" >
  <v-card-title class="primary--text grey lighten-3" >
    {{phenotypeName}}
    </v-card-title>
  <v-card-text>
  <div class="d-flex flex-column my-3">
    <v-progress-linear v-if="loading" class="mt-n3 mb-1" color="primary lighten-4" indeterminate height="6"></v-progress-linear>
    <v-row>
      <v-col cols = "6">
          <v-list dense flat shaped>
            <v-list-item-group active-class="" >
              <v-list-item class="my-n1" @click="getPhenotype(item.id)" :class="item.class" v-for="item in hierarchyItems" :key="item.id" >
                <v-divider vertical class="mr-2 grey " :class="item.margin"></v-divider>
                <div >{{item.name}}<span class="pl-1 grey--text caption" >({{item.count}})</span> </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- <v-treeview hoverable activatable  v-model="tree" :open="initiallyOpen" item-key="id"  :active="phenotypeId" selected-color="secondary"   dense open-all :items="hierarchyItems"></v-treeview> -->
      </v-col>
      <v-col cols = "6">
        <div class="d-flex flex-row mb-2">
          <div class="text-subtitle-2 grey--text text--darken-2 font-weight-bold">SYNONYMS:</div>
          <div class="ml-2">
              {{synonyms.join(', ')}}
          </div>
        </div>
        <div class="mb-1 d-flex flex-row">
          <div class="mt-2 text-subtitle-2 grey--text text--darken-2 font-weight-bold">ASSOCIATED GENES</div>
          <div class="ml-1 mt-2 caption">({{totalgenes}})</div>
          <div class="ml-auto mb-1">
            <v-btn small :disabled="genes.length == 0"  color="secondary" outlined @click="onGeneSelected()">
              Choose Genes
            </v-btn>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <!-- <v-btn v-for="gene in genes" :key="gene.geneId" color="primary" small rounded outlined @click="onGeneSelected(gene.geneSymbol)">{{gene.geneSymbol}}</v-btn> -->
          <v-chip v-for="gene in genes" :key="gene.id" color="primary" small rounded outlined >{{gene.name}}</v-chip>

        </div>
        <div>
            <v-pagination  v-model="currentPage"  class="my-4" @input="getGenes"  :length="totalPageCount"></v-pagination>
        </div>

      </v-col>
    </v-row>
  </div>


  </v-card-text>
</v-card>


</template>
<script>
import {getPhenotypeHpo, getPhenotypeHpoChildren,getPhenotypeHpoParents, getAnnotation} from '@/api/gene/hpo.js'
export default {
  name: 'PhenotypeInfo',
  props:{
    id:{
      type: String,
      default: ''
    }
  },
  data:()=>({
    attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    hierarchyItems:[],
    loading: true,
    phenotypeId: null,
    phenotypeName: null,
    genes: [],
    totalgenes: 0,
    synonyms:[],
    totalPageCount: null,
    currentPage: 1,
    pageSize: 50
  }),
  methods:{
    onGeneSelected(){
      // this.loading = true
      // const _this = this
      // this.$emit('onGeneSelected', gene)
      this.$emit('onGeneSelected', this.genes.map(item => item.name), 'Phenotype: ' + this.phenotypeName)
      this.loading = false
      // getPhenotypeGenesHpo(this.phenotypeId, 0, -1, (res)=>{
      //     const r = res.genes.map(g => g.geneSymbol)
      //     _this.loading = false
      // })
    },
    getPhenotype(id){
      // console.log('SELECTED ITEM', this.selectedItem)
      this.phenotypeId = id
      this.loading = true
      this.currentPage = 1
      this.genes = []
      this.totalgenes = 0
      this.synonyms = []
      const _this = this
      getPhenotypeHpo(id, function(res){
        let r = []
        getPhenotypeHpoParents(id,(rs) => {
          r = rs.map(item=> { return { id: item.id, name: item.name , class:'', margin: 'ml-n2', count: item.descendantCount }})
          r.push({ id: id , name: ' ' + res.name, class: 'accent', margin:'ml-1', count: res.descendantCount })
          getPhenotypeHpoChildren(id,(rsc) => {
            r = r.concat(rsc.map(item=> { return { id: item.id, name: item.name ,class:'', margin:'ml-4', count: item.descendantCount}}).sort((a,b) => { return (a.name>b.name ? 1 : ( a.name<b.name ? -1 : 0) )}))
        })       
        })
        _this.hierarchyItems = r

        _this.synonyms = res.synonyms
        _this.phenotypeName = res.name
        _this.getGenes()
      })
    },
    getGenes(){
        this.loading = true
        // let offset = (this.currentPage - 1) * this.pageSize
        const _this = this
        getAnnotation(this.phenotypeId, (res)=>{
            _this.totalPageCount = 1// Math.ceil(res.geneCount / _this.pageSize)
            _this.totalgenes = res.genes.length //res.geneCount
            _this.genes = res.genes
            _this.loading = false
        })
    }

  },
  created(){
    this.phenotypeId = this.id
    this.getPhenotype(this.phenotypeId)
  }

}
</script>