<template>
    <div>
        <v-rect :config="rectConfig"></v-rect>
        <v-line v-for="(l,index) in markerConfigs" :key="'l'+ index" :config="l" ></v-line>
        <v-text v-for="(s,index) in textConfigs" :key="'t'+ index " :config="s" ></v-text>
        <v-line  :config="lineConfig"/>  
    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import { toScatedX } from '@/utils/tad.js'

Vue.use(VueKonva);
export default {
    name: 'Axis',
    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        // end:{
        //     type:Number,
        //     required:true
        // },
        // start:{
        //     type:Number,
        //     required:true
        // },
        baseLineY:{
            type:Number,
            default:500
        }
    },
    data:()=>({
        stepCount:10,
    }),
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        }
    },
    computed:{
        start(){
            return this.tadInfo.min
        },
        end(){
            return this.tadInfo.max
        },
        rectConfig(){
            return {x: this.toX(this.start) ,y: this.baseLineY -15, width: this.toX(this.end) - this.toX(this.start) + 20, height:45, strokeWidth:0 , fill: '#f0f0f0' }
        },

        steps(){
            let x = new Array(this.stepCount+1).fill(0).map(
                (item,index) => {
                    return {
                        val: this.start + index * this.stepSize,
                        pos:   this.toX(this.start + index * this.stepSize)
                    }
                }
            )
            return x
        },
        markerConfigs(){
            return this.steps.map((s,index) => {
                return {
                    x:3,
                    y:0, 
                    points: [s.pos - index*0.4, this.baseLineY - 3, s.pos - index*0.4, this.baseLineY +3], 
                    stroke:'black'
                }
            }
            )
        },
        textConfigs(){
            return this.steps.map((s,index) => {
                let offset = 0
                if(index == 0){
                    offset = 0
                } else if (index == this.stepCount ){
                    offset =  2*(String(s.val).length*3) +3
                } else {
                    offset =  String(s.val).length*3 +3
                }
                return {x:s.pos - offset ,y:this.baseLineY + 4, text:s.val, fontSize:13, fill: 'grey' }
            }
            )
        },
        stepSize(){
            return Math.round( (this.end - this.start) / this.stepCount );
        },
        lineConfig(){
            // const end = this.toX(this.stepCount * this.stepSize) 
            return {
                x: 3,
                y: 0,
                points: [0, this.baseLineY, this.toX(this.end), this.baseLineY],
                stroke: 'black',
                strokeWidth: 1,
            } 
        }
    },

}
</script>