import { getDatasetsBySample } from "@/api/dataset.js";
import { getDatasetSamples } from "@/api/user/dataset";
export default {
    data() {
        return {
            onDataLoadedCallback:()=>{},
            pSamples: null,
            sampleStatues: {
                DONE: {
                  name: "DONE",
                  color: "#689879",
                  icon: "mdi-check",
                  msg: "Analyzed",
                },
                IN_PROGRESS: {
                  name: "IN_PROGRESS",
                  color: "#e9a643",
                  icon: "mdi-progress-check ",
                  msg: "Analysis in Progress",
                },
                NOT_ANALYZED: {
                  name: "NOT_ANALYZED",
                  color: "#93a4de",
                  icon: " mdi-creation-outline",
                  msg: "Not Analyzed",
                },
              },
        }
    },
    
    computed:{
        samples() {
            if (this.pSamples && this.pSamples.size > 0) {
              return Array.from(this.pSamples, ([sampleName, info]) => ({ sampleName, info }))
                .map((item) => {
                  item.svDataset = item.info.datasets.find((x) => x.datasetType.name == "SV") || null;
                  item.snvDataset = item.info.datasets.find((x) => x.datasetType.name == "SNV") || null;
                  item.hrStatus = this.sampleStatues[item.info.status];
                  item.disease_status = item.info.disease_status;
                  (item.st = item.info.status), (item.decision = item.info.decision);
                  return item;
                }).sort((a,b) => ( a.sampleName > b.sampleName ? 1 : ( a.sampleName < b.sampleName ? -1 : 0 )))
            } else {
              return [];
            }
          }
    },
    methods: {
        loadSamples: function (sampleId, cohortId) {
            const _this = this
            getDatasetsBySample(sampleId, function (res) {
                let tmpSamples = null;
                if (res[0].recordCount > 0) {
                    getDatasetSamples(cohortId, res[0].id, (rs) => {
                        tmpSamples = new Map(rs.map((item) => [item.sampleName, { status: item.status.status, disease_status:item.disease_status, decision: item.status.decision, type: [res[0].datasetType.name], datasets: [res[0]] }]));
                        if (res[1].recordCount > 0) {
                            getDatasetSamples(cohortId, res[1].id, (rs) => {
                                rs.forEach((x) => {
                                    const s = tmpSamples.get(x.sampleName);
                                    if (s && x.status.status != s.status) {
                                        s.status = "IN_PROGRESS";
                                        s.datasets.push(res[1]);
                                        s.type.push(res[1].datasetType.name);
                                    } else {
                                        tmpSamples.set(x.sampleName, { status: x.status.status, disease_status:x.disease_status, decision: x.status.decision, type: [res[0].datasetType.name, res[1].datasetType.name], datasets: [res[0], res[1]] });
                                    }
                                });
                                _this.pSamples = tmpSamples;
                                _this.onDataLoadedCallback()

                            });
                        }
                    });
                } else {
                    if (res[1].recordCount > 0) {
                        getDatasetSamples(cohortId, res[1].id, (rs) => {

                            _this.pSamples = new Map(rs.map((item) => [item.sampleName, { status: item.status.status, disease_status:item.disease_status,  decision: item.status.decision, type: [res[1].datasetType.name], datasets: [res[1]] }]));
                            _this.onDataLoadedCallback()
                        });
                    }
                }
            });
        },
    },
}