<template>
  <div>
    <v-row justify="center">
      <v-dialog width="1000px" v-model="show" @click:outside="closeDialog()">
        <v-card min-height="500px">
          <v-card-title>
            <div class="text-h5">Search for Genes</div>
          </v-card-title>
          <v-card-text>
            <v-sheet color="grey lighten-4 py-6" rounded="lg">
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col cols="10">
                  <div class="d-flex">
                    <v-autocomplete
                      clearable
                      dense
                      solo
                      @keyup.enter="onSearch()"
                      @change="onAutoCompleteItemSelected"
                      v-model="autoCompleteSelect"
                      :items="autoCompleteItems"
                      :loading="autoCompleteLoading"
                      :search-input.sync="autoCompleteSearch"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      item-value="id"
                      :label="'Search for ' + searchType"
                      :placeholder="'e.g. ' + (searchType === 'Diseases' ? 'Marfan syndrome' : 'Abnormality of limbs')"
                      prepend-icon="mdi-database-search"
                      return-object
                    >
                      <template slot="prepend-item">
                        <div class="grey lighten-3 d-flex flex-row">
                          <div class="caption grey--text">{{ autoCompleteItems.length }} of {{ autoCompleteResultTotalItems }} {{ searchType }} dispalyed</div>
                          <div class="ml-auto">
                            <v-btn text x-small color="secondary" @click="onSearch()">Show all results</v-btn>
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-btn color="secondary" class="ml-2" outlined elevation="2" @click="onSearch()">Search</v-btn>
                  </div>
                  <div class="mt-n8 ml-8">
                    <v-radio-group dense row v-model="searchType" @change="onSearchTypeChanged()">
                      <v-radio label="Diseases" value="Diseases"></v-radio>
                      <v-radio label="Phenotypes" value="Phenotypes"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
            <v-data-table v-show="!showDetail" :loading="loading" :headers="headers" :items="searchResult" :items-per-page="10" class="elevation-1" item-key="id">
              <template v-slot:item.actions="{ item }">
                <v-icon large @click="viewDetail(item)"> mdi-menu-right </v-icon>
              </template>
            </v-data-table>
            <v-card class="d-flex flex-row mt-2" v-if="showDetail" outlined>
              <div style="width: 30px" class="grey lighten-3 full-height d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mt-auto mb-auto" icon v-bind="attrs" v-on="on" @click="showDetail = false">
                      <v-icon large color="primary"> mdi-menu-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Back to list</span>
                </v-tooltip>
              </div>
              <div style="width: 100%">
                <DiseaseInfo v-if="searchType === 'Diseases'" @onGeneSelected="onGeneSelected" :diseaseName="selectedItem.name" :diseaseId="selectedItem.id"></DiseaseInfo>
                <PhenotypeInfo v-else @onGeneSelected="onGeneSelected" :id="selectedItem.id"></PhenotypeInfo>
              </div>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey" text @click="closeDialog()"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { searchHpoDiseases, searchHpoTerms } from "@/api/gene/hpo.js";
import DiseaseInfo from "@/components/defaultAnalysis/searchGene/DiseaseInfo.vue";
import PhenotypeInfo from "@/components/defaultAnalysis/searchGene/PhenotypeInfo.vue";
export default {
  name: "SearchGene",
  components: { DiseaseInfo, PhenotypeInfo },
  data: () => ({
    show: false,
    searchItem: "",
    searchType: "Diseases",
    loading: false,
    autoCompleteMaxItems: 15,
    searchResult: [],
    headers: [
      {
        text: " Identifier",
        align: "start",
        sortable: true,
        value: "id",
        class: "accent",
      },
      { text: "Name", value: "name", cellClass: "font-weight-bold primary--text", class: "accent" },
      { text: "MORE", value: "actions", class: "accent", cellClass: "secondary--text", align: "right", width: "40px", sortable: false },
    ],
    autoCompleteLoading: false,
    autoCompleteItems: [],
    autoCompleteSearch: null,
    autoCompleteSelect: null,
    autoCompleteResultTotalItems: null,
    selectedItem: null,
    showDetail: false,

    selectedGenes: [],
    selectedGenesTitle: null,
  }),
  watch: {
    autoCompleteSearch(val) {
      //        val &&  val !== this.autoCompleteSelect && this.loadSuggestion(val)
      if (val && val.length > 2) {
        clearTimeout(this.autoCompleteTimerId);
        this.autoCompleteTimerId = setTimeout(() => {
          this.loadSuggestion(val);
        }, 200);
      } else {
        this.autoCompleteItems = [];
      }
    },
  },
  props: {},
  methods: {
    onSearchTypeChanged() {
      this.showDetail = false;
      this.searchResult = [];
    },
    viewDetail(item) {
      this.selectedItem = item;
      this.showDetail = true;
    },
    loadSuggestion(val) {
      // if (this.autoCompleteLoading) return
      this.showDetail = false;
      this.searchResult = [];
      this.autoCompleteLoading = true;
      const _this = this;
      const max = this.autoCompleteMaxItems;

      if (_this.searchType == "Diseases") {
        searchHpoDiseases(val, 0, max, (res) => {
          _this.autoCompleteItems = res.results.map((item) => {
            return { id: item.id, name: item.name };
          });
          _this.autoCompleteResultTotalItems = res.totalCount;
        });
      } else {
        searchHpoTerms(val, 0, max, (res) => {
          _this.autoCompleteItems = res.terms;
          _this.autoCompleteResultTotalItems = res.totalCount;
        });
      }

      // searchHpo(val , 0, max, function(res){
      //   console.log(res)
      //   _this.autoCompleteLoading = false
      //   if ( _this.searchType == 'Diseases'){
      //     _this.autoCompleteItems = res.diseases.map(item => {return { id: item.diseaseId, name:item.dbName }})
      //     _this.autoCompleteResultTotalItems = res.diseasesTotalCount
      //   } else {
      //     _this.autoCompleteItems = res.terms
      //     _this.autoCompleteResultTotalItems = res.termsTotalCount
      //   }
      // })
    },
    onGeneSelected(genes, type) {
      this.show = false;
      this.$emit("onGeneSelected", genes, type);
    },
    closeDialog() {
      this.show = false;
      this.$emit("onClose");
    },
    onSearch() {
      this.showDetail = false;
      this.search(this.autoCompleteSearch, -1);
    },

    onAutoCompleteItemSelected(item) {
      this.search(item.name, -1);
    },

    search(searchItem, max) {
      const _this = this;
      this.loading = true;
      this.autoCompleteLoading = false;
      this.autoCompleteItems = [];
      clearTimeout(this.autoCompleteTimerId);

      if (_this.searchType == "Diseases") {
        searchHpoDiseases(searchItem, 0, max, (res) => {
          _this.searchResult = res.results.map((item) => {
            return { id: item.id, name: item.name };
          });
          _this.loading = false;
        });
      } else {
        searchHpoTerms(searchItem, 0, max, (res) => {
          _this.searchResult = res.terms;
          _this.loading = false;
        });
      }

      //this.$emit('onGeneSelected')
    },
  },
  created() {
    this.show = true;
  },
};
</script>