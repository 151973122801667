<template>
  <div>
    <div class="d-flex flex-column mb-1">
      <div class="text-h5 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-account-injury-outline</v-icon>Samples</div>
      <div class="d-flex flex-row mb-2 mt-6">
        <div class="d-flex flex-row  justify-center filterFrame flex-grow-1">
          <span class="body-2 grey--text text--darken-2 mt-2 mr-2">Disease Status:</span>
          <v-checkbox dense  v-model="selectedDiseaseStatuses" hide-details @change="savePrefs" v-for="item in diseaseStatuses" :value="item.value" :key="item.value">
            <template v-slot:label >
              <span class="caption ml-n2">{{ item.title }}</span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex flex-row justify-center filterFrame flex-grow-1 mx-4">
          <span class="body-2 grey--text text--darken-2 mt-2 mr-2 ">Final Decision:</span>
          <v-checkbox dense  v-model="filteredDecisions" hide-details @change="savePrefs" v-for="item in Object.keys(sampleDecisions)" :value="item" :key="item">
            <template v-slot:label >
              <span class="caption ml-n2">{{ sampleDecisions[item].text }}</span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex flex-row  justify-center filterFrame flex-grow-1">
          <span class="body-2 grey--text text--darken-2 mt-2 mr-2 ">Analysis Status:</span>
          <v-checkbox dense  v-model="filteredStatuses" hide-details @change="savePrefs" v-for="item in sampleStatues" :value="item.name" :key="item.name">
            <template v-slot:label >
              <span class="caption ml-n2">{{ item.msg }}</span>
            </template>
          </v-checkbox>
        </div>


        <!-- <div class="d-flex flex-row">
          <v-checkbox dense v-model="filteredStatuses" hide-details v-for="item in sampleStatues" :label="item.msg" :value="item.name" :key="item.name"></v-checkbox>
        </div> -->
      </div>
    </div>
    <v-data-table
      :items-per-page="30"
      :loading="loading"
      loading-text="Retrieving samples ..."
      ref="detailGrid"
      :headers="headers"
      :items="sampleList"
      @click:row="clickRow"
      class="elevation-1 mb-8"
      :item-key="'sampleName'"
      single-select
      v-model="selected"
      :search="searchedFieldName"
    >
      <template v-slot:header.sampleName="{}">
        <v-text-field color="white" dark outlined dense v-model="searchedFieldName" append-icon="mdi-magnify" label="Sample Name" class="mt-2 mb-1 white--text" style="width: 350px !important" single-line hide-details></v-text-field>
      </template>
      <template v-slot:item.disease_status="{item}">
        <div> {{ item.disease_status }} <v-icon x-small color="red" class="mb-3" v-if="item.disease_status=='affected'">mdi-asterisk</v-icon></div>
      </template>      
      <template v-slot:item.hrStatus="{ item }">
        <div>
          <v-icon :color="sampleStatues[item.info.status].color"> {{ sampleStatues[item.info.status].icon }} </v-icon>
          <span :style="'color:' + sampleStatues[item.info.status].color"> {{ sampleStatues[item.info.status].msg }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import loadSampleList from "@/mixins/lucidAllAnalysis/loadSampleList.js";
import {save_user_pref, get_user_pref} from "@/utils/userPreferences.js"
export default {
  name: "LucidAllAnalysisSampleList",
  mixins: [loadSampleList],
  props: {
    sampleId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      searchedFieldName: null,
      selected: [],
      loading: true,
      headers: [
        { text: "Sample", align: "center", sortable: false, value: "sampleName", class: "primary white--text font-weight-meduim text-center", width: "350", cellClass: "variantCell" },
        { text: "Disease Status", value: "disease_status", class: "primary white--text  ", cellClass: "variantCell text-uppercase", sortable: false, align: "center" },
        { text: "Analysis Status", value: "hrStatus", class: "primary white--text text-center", cellClass: "variantCell", sortable: false, align: "center" },
        { text: "Final Decision", align: "center", sortable: false, value: "info.decision.title", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
      ],
      filteredDecisions: [ "2", "3", "0","1"],
      filteredStatuses: ["NOT_ANALYZED", "IN_PROGRESS", "DONE"],
      diseaseStatuses:[
        {title:'Affected', value:'affected',color:'red'},
        {title:'Unaffected', value:'unaffected',color:'grey'},
      ],
      sampleDecisions: {
        0: {
          color: "grey",
          icon: "mdi-check-all",
          text: "Not Decided",
        },
        1: {
          color: "green",
          icon: "mdi-check-all",
          text: "Solved",
        },
        2: {
          color: "red",
          icon: "mdi-window-close",
          text: "Unsolved",
        },
        3: {
          color: "orange",
          icon: "mdi-crosshairs-question",
          text: "Unsolved with Strong Candidates",
        },
      },
      selectedDiseaseStatuses:['affected'],
      selectedSample: null,
      // samples: null,
    };
  },
  methods: {
    clickRow(item) {
      // console.log(item);
      // console.log(row);
      this.$router.push({ name: "LucidAllAnalysisSampleVariants", params: { id: this.sampleId, cohortid: this.cohortId, sampleid: item.sampleName } });
    },
    onSamplesLoadedCallback() {
      this.loading = false;
    },
    savePrefs(){
      console.log('savePref')
      const p = {
        selectedDiseaseStatuses: this.selectedDiseaseStatuses,
        filteredDecisions: this.filteredDecisions,
        filteredStatuses: this.filteredStatuses,
      }
      save_user_pref('IndividualAnalysisSamplesFilters', p)
    }
  },

  computed: {
    decisionStats() {
      let stats = {
        "Not Decided": 0,
        Solved: 0,
        Unsolved: 0,
        "Unsolved with Strong Candidates": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item?.decision?.title || "Not Decided"]++;
      });
      return stats;
    },
    statusStats() {
      let stats = {
        Analyzed: 0,
        "Analysis in Progress": 0,
        "Not Analyzed": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item.hrStatus.msg]++;
      });
      return stats;
    },
    sampleList() {
      return this.samples.filter((item)=>this.selectedDiseaseStatuses.indexOf(item.disease_status)>=0).filter((item) => this.filteredStatuses.indexOf(item.st) >= 0).filter((item) => this.filteredDecisions.indexOf((item.decision?.id || 0).toString()) >= 0);
    },
  },
  created() {
    this.onDataLoadedCallback = this.onSamplesLoadedCallback;
    this.loadSamples(this.sampleId, this.cohortId);
    const savedFilters = get_user_pref('IndividualAnalysisSamplesFilters')
    if(savedFilters){
      this.selectedDiseaseStatuses= savedFilters.selectedDiseaseStatuses
      this.filteredDecisions = savedFilters.filteredDecisions
      this.filteredStatuses = savedFilters.filteredStatuses
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .SampleList.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.14) !important;
}

::v-deep tr.v-data-table__selected {
  background: var(--v-accent-base) !important;
  border-bottom: 0px !important;
}

.theme--light.v-data-table::v-deep {
  background-color: transparent;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.variantCell {
  height: 45px !important;
  border-bottom: 1px solid #0393a850 !important;
}

.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
}

.filterFrame{
  background-color: #eeeeee90;
  // padding-left: auto;
  // padding-right: auto;
  padding-bottom: 6px;
  padding-top: 2px;
  border-radius: 6px;
}
</style>