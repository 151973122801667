<template>
  <v-line :config="lineConfig" />
</template>
<script>
import VueKonva from "vue-konva";
import Vue from "vue";
Vue.use(VueKonva);
import { toScatedX } from "@/utils/tad.js";

export default {
  name: "TrackSeparator",
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    y: {
      type: Number,
      default: 500,
    },
  },
  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
  },
  computed: {
    lineConfig() {
      return {
        x: this.toX(this.tadInfo.min),
        y: 0,
        points: [0, this.y, this.toX(this.tadInfo.max), this.y],
        stroke: "grey",
        strokeWidth: 0.3,
        
    };
    },
  },
};
</script>