import ApiIntegrface from "../api"
import store from '@/store'
export function shareResult(cohortId, sampleId, payload, callback, successMessage){
    const api = new ApiIntegrface()
    const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/projects/' + sampleId + '/share'
    api.post(url, payload, callback, null, successMessage)
  }

export function getSharedWithMeList(callback){
    const api = new ApiIntegrface()
    const url = '/api/v1/shared-with-me'
    api.get(url, null, callback, null)
}

export function getSharedWithMeItem(id, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/shared-with-me/'+id
  api.get(url, null, callback, null)
}