<template>
  <div class="pa-12">
    <div class="d-flex flex-row">
      <div class="text-h4 font-weight-bold mb-4 " style="width: 380px;">
        <icon xLarge color="primary" id="machine-learning-model"></icon> 
        {{ $route.meta.routeTitle }}
        
      </div>
      <div class="mt-2  text-h6 grey--text text--darken-2">
        
        <span >  <router-link :to="{ name: 'UserCohorts'}"  style="text-decoration:none !important"> Cohorts </router-link>  > </span>
        <span v-if="$route.name == 'LucidAllAnalysisCohortOverview'">{{ cohortName }} - {{ projectName }} > Overview</span>
        <span v-else > <router-link :to="{ name: 'LucidAllAnalysisCohortOverview', params: { id: sampleId, cohortid: cohortId } }"  style="text-decoration:none !important"> {{ cohortName }} - {{ projectName }} </router-link> </span>
        
        <span v-if="$route.name == 'LucidAllAnalysisSamples'">  > Samples</span>
        <span v-if="sampleName">  ><router-link :to="{ name: 'LucidAllAnalysisSamples', params: { id: sampleId, cohortid: cohortId } }"  style="text-decoration:none !important"> Samples</router-link> {{ ' > ' + sampleName  }} </span>
        <span v-if="$route.name == 'LucidAllCohortAnalysis'">  > Cohort Analysis</span>
        <span v-if="$route.name == 'DefaultAnalysis'">  > Explorer</span>       

      </div>

    </div>
      
    <router-view :key="$route.path"></router-view>

</div>
</template>
<script>
// import { getCohort } from "@/api/cohort.js";
import { getCohort } from "@/api/user/dataset.js";
import { getDatasetsBySample } from "@/api/dataset.js";

export default {
    name:"LucidAllAnalysis",
    
    data:()=>({
        sampleId: null,
        cohortId: null,
        cohortName:null,
        projectName: null,
        datasets: [],

    }),
    created(){
        this.sampleId = Number(this.$route.params.id);
        this.cohortId = Number(this.$route.params.cohortid);
        const _this = this
        getCohort(this.cohortId,(res)=>{_this.cohortName=res.name})
        getDatasetsBySample(this.sampleId, (res) => { 
          _this.projectName = res[0]?.sample.name || null  
          _this.datasets = res
        }
          )
    },
    computed:{
      sampleName(){
        return this.$route.params.sampleid
      }
    }
}
</script>