 <template>
  <v-menu v-if="compact" right v-model="menu" open-on-click offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-account-group-outline</v-icon>
    </template>

    <v-card width="600px" max-height="">
      <v-card-title class="grey lighten-2 d-flex flex-row pt-1 pb-1">
        {{ organizationName }}
        <v-btn icon class="ml-auto" color="grey" @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="(info, idx) in comments" :key="'u-' + idx">
          <div class="d-flex flex-row mt-3">
            <div class="black--text">{{ info.first_name + " " + info.last_name }}</div>
            <div v-if="info.label" class="ml-auto font-weight-medium text-uppercase" :style="'color:' + info.labelColor">{{ info.label }}</div>
            <div v-else class="ml-auto grey--text">[ NO LABEL]</div>
          </div>
          <div class="body-2 mt-1 mb-2 ml-4 mr-4">{{ info.note }}</div>
          <v-divider v-if="idx < comments.length - 1"></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
  <div v-else>
    <div class="body-2 mb-2 font-weight-medium grey--text text--darken-1">
      Other {{ organizationName }} users' comments:
    </div>
    <v-card outlined>
      <v-card-text>
        <div v-for="(info, idx) in comments" :key="'u-' + idx">
          <div class="d-flex flex-row mt-3">
            <div class="black--text">{{ info.first_name + " " + info.last_name }}</div>
            <div v-if="info.label" class="ml-auto font-weight-medium text-uppercase" :style="'color:' + info.labelColor">{{ info.label }}</div>
            <div v-else class="ml-auto grey--text">[ NO LABEL]</div>
          </div>
          <div class="body-2 mt-1 mb-2 ml-4 mr-4">{{ info.note }}</div>
          <v-divider v-if="idx < comments.length - 1"></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "OrganizationComments",
  props: {
    comments: {
      type: Array,
      required: true,
    },
    compact: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    organizationName() {
      // console.log('store',this.$store.state)
      return this.$store.state.Organization.name;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field--outlined .fieldset {
  border: 0px solid red !important;
}
</style>