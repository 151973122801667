<template>
  <v-dialog v-model="show" persistent max-width="400" >
  <v-card>
    <v-card-title>List of Genes</v-card-title>
    <v-card-subtitle>Each line should contain one gene</v-card-subtitle>
    <v-card-text>
      <v-textarea outlined v-model="genesText"></v-textarea>
    </v-card-text>
    <v-card-actions>
     <v-spacer></v-spacer>
     <v-btn text @click="close()" color="grey">CLOSE</v-btn>
      <v-btn text @click="apply()" color="primary">APPLY</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>
// import App from '@/main'
export default {
  name: "ImportGeneList",
  props:{
    genes:{
      type: Array,
      default: ()=>[]
    }
  },
  data:()=>({
    note: null,
    show: false,
    genesText: ''
  }),
  computed:{
    geneList(){
      return this.genesText.split('\n').map(item => item.trim()).filter(item => item != '')
    }
  },
  created(){
    this.show = true
    this.genesText = this.genes.join('\n')
  },
  methods:{
    apply(){
      this.$emit('onApply', this.geneList)
      this.close()
    },
    close(){
      this.show = false
      this.$emit('onClose')
    }

  },


}
</script>