 <template>
  <div v-if="selectedPropertiesLabels.length > 0" class="d-flex justify-start flex-wrap rounded-lg mt-2 px-1 mb-1" :style="dense?'':'min-height: 50px'">
    <template v-for="item in selectedPropertiesLabels">
      <v-tooltip :key="item.id" bottom max-width="400" :open-delay="300" :disabled="item.truncatedText == null">
        <template v-slot:activator="{ on, attrs }">
          <v-chip :small="dense" :key="item.id" :class="dense ? 'my-1 mr-1 px-1' :'ma-2 pl-4'" v-bind="attrs" v-on="on" :close="enableCloseChips" :color="color" rounded="0" label outlined @click:close="clearFilter(item.id)">
            <span v-html="item.truncatedText != null ? item.truncatedText : item.fullText"></span>
          </v-chip>
        </template>
        <span v-html="item.fullText"> </span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
export default {
    name: 'SelectedFilterChipsList',
    props:{
        selectedPropertiesLabels:{
            type: Array,
            required: true
        },
        enableCloseChips:{
            type: Boolean,
            required: true
        },
        dense:{
          type:Boolean,
          default: false
        },
        color:{
          type:String,
          default: 'primary lighten-1'
        }
    },
    methods:{
        clearFilter(val){
            this.$emit('onClearFilter', val)
        }
    }
}
</script>