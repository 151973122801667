import ApiIntegrface from "../api"
import store from '@/store'
export function getCohortAnalysis(cohortId, projectId, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/analysis`
  api.get(url, null, callback)
}

export function getProjectGeneLabel(callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/projectgenelabels`
  api.get(url, null, callback)
}

export function setProjectGeneLabel(cohortId, projectId, gene, labelId, successMsg, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/genes`
  const payload = {
    label:labelId,
    gene:gene
  }
  api.post(url, payload, callback,null,successMsg)
}

export function setProjectGeneNote(cohortId, projectId, gene, note, successMsg, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/genes`
  const payload = {
    gene:gene,
    note:note
  }
  api.post(url, payload, callback,null,successMsg)
}

export function updateProjectGeneLabel(cohortId, projectId, gene, labelId, successMsg, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/genes/${gene}`
  const payload = {
    label:labelId
  }
  api.put(url, payload, callback,null,successMsg)
}

export function getProjectGeneRegulome(cohortId, projectId, gene, payload, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/genes/${gene}/regulome`
  api.post(url, payload, callback,null,null,null,false)
}

export function getProjectGenes(cohortId, projectId, payload, callback){
  const api = new ApiIntegrface()

  let url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/projects/${projectId}/genes/search`
  api.post(url, payload, callback)
}

