<template>
  <div>
    <v-rect :config="rectConfig"></v-rect>
    <v-text :config="textConfig"></v-text>
    <HighlightBar :startX="svBarStart" :width="svBarWidth" :height="TRACK_HEIGHT" :baseLineY="baseLineY"></HighlightBar>
    <HighlightBar :startX="secondaryHighlightStart" :width="secondaryHighlightWidth" :height="TRACK_HEIGHT" :baseLineY="baseLineY"></HighlightBar>
   
    <Mutation :tadInfo="tadInfo" v-for="(m, index) in mutations" :key="index" @onChanged="onChanged" :baseLineY="baseLineY + 10" :info="m" v-on:onMutationMouseOver="onMutationMouseOver" v-on:onMutationMouseOut="onMutationMouseOut"  @onSvBar="onSvBar" ></Mutation>
    <!-- <Mutation :tadInfo="tadInfo"  @onChanged="onChanged" :baseLineY="baseLineY + 10" :info="mutations[0]" v-on:onMutationMouseOver="onMutationMouseOver" v-on:onMutationMouseOut="onMutationMouseOut"  @onSvBar="onSvBar" ></Mutation> -->
    <TrackSeparator :tadInfo="tadInfo" :y="baseLineY+ TRACK_HEIGHT"></TrackSeparator>

  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Mutation from "@/components/defaultAnalysis/tad/Mutation.vue";
import { toScatedX } from "@/utils/tad.js";
import HighlightBar from '@/components/defaultAnalysis/tad/HighlightBar.vue'
import TrackSeparator from "@/components/defaultAnalysis/tad/TrackSeparator.vue";

Vue.use(VueKonva);
export default {
  name: "MutationTrack",
  components: { Mutation, HighlightBar, TrackSeparator },
  props: {
    // end:{
    //     type:Number,
    //     default:0
    // },
    tadInfo: {
      type: Object,
      required: true,
    },
    mutations: {
      type: Array,
      default: () => [],
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    trackName:{
      type: String,
      required: true
    }
  },
  data: () => ({
    selectedMutations: [],
    svBarStart: 0,
    svBarWidth: 0,
    TRACK_HEIGHT: 45,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0
    },
    showSecondaryHighlight: false,
  }),
  mounted(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },
  computed: {
    secondaryHighlightStart(){
      return this.toX(this.secondaryHightlightInfo.start)
    },
    secondaryHighlightWidth(){
      return this.showSecondaryHighlight? this.secondaryHightlightInfo.widthInPx : 0
    },
    textConfig() {
      return {
        x: 3,
        y: this.baseLineY + 2,
        text: this.trackName,
        width: 300,
        fontSize: 13,
        fontStyle: "bold",
        fill: "#8dafdf",
      };
    },
    start() {
      return this.tadInfo.min;
    },
    end() {
      return this.tadInfo.max;
    },
    rectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY, width: this.toX(this.end), height: this.TRACK_HEIGHT, strokeWidth: 0, fill: "#d7eff550" };
    },
  },
  methods: {
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
    onSvBar(start, width){
        this.svBarStart = start
        this.svBarWidth = width
        this.$emit('onSvBar',start, width)
       
    },
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onMutationMouseOut(mutation) {
      this.$emit("onMutationMouseOut", mutation);
    },
    onMutationMouseOver(mutation) {
      this.$emit("onMutationMouseOver", mutation);
    },
    onChanged(id, selected) {
      if (selected) {
        this.selectedMutations.push(id);
      } else {
        const i = this.selectedMutations.indexOf(id);
        if (i > -1) {
          this.selectedMutations.splice(i, 1);
        }
      }
      this.$emit("onChanged", this.selectedMutations);
    },
  },
};
</script>