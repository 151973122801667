 <template>
  <div id="FamilyChart" class="f3"></div>
  <!-- <div>dimdim</div> -->
</template>
<script>
import f3 from "family-chart"; // npm i family-chart
import "@/assets/css/family-chart.css"; // create file 'family-chart.css' in same directory, copy/paste css from examples/create-tree
import { getSamplePedigree } from "@/api/user/dataset.js";
export default {
  name: "PatientPedigree",
  props: {
    sampleName: {
      type: String,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    pedigreeDt: [
      {
        id: "11_0351",
        rels: {
          spouses: [],
          father: "",
          mother: "",
          children: [],
        },
        data: {
          sample_name: "11_0351",
          disease_status: "affected",
          gender: "F",
        },
      },
      {
        id: "11_0352",
        rels: {
          spouses: [],
          father: "",
          mother: "",
          children: [],
        },
        data: {
          sample_name: "11_0351",
          disease_status: "affected",
          gender: "unknown",
        },
      },      
    ],
    // pedigreeDt: [
    //   {
    //     id: "0",
    //     rels: {
    //       spouses: ["8c92765f-92d3-4120-90dd-85a28302504c"],
    //       father: "0c09cfa0-5e7c-4073-8beb-94f6c69ada19",
    //       mother: "0fa5c6bc-5b58-40f5-a07e-d787e26d8b56",
    //       children: ["ce2fcb9a-6058-4326-b56a-aced35168561", "f626d086-e2d6-4722-b4f3-ca4f15b109ab"],
    //     },
    //     data: {
    //       sample_name: "Agnus",
    //       disease_status: "affected",
    //       gender: "F",
    //     },
    //   },
    //   {
    //     id: "8c92765f-92d3-4120-90dd-85a28302504c",
    //     data: {
    //       gender: "F",
    //       sample_name: "Andrea",
    //       disease_status: "affected",
    //     },
    //     rels: {
    //       spouses: ["0"],
    //       children: ["ce2fcb9a-6058-4326-b56a-aced35168561", "f626d086-e2d6-4722-b4f3-ca4f15b109ab"],
    //     },
    //     main: true,
    //   },
    //   {
    //     id: "0c09cfa0-5e7c-4073-8beb-94f6c69ada19",
    //     data: {
    //       gender: "M",
    //       sample_name: "Zen",
    //       disease_status: "unaffected",
    //     },
    //     rels: {
    //       children: ["0"],
    //       spouses: ["0fa5c6bc-5b58-40f5-a07e-d787e26d8b56"],
    //     },
    //   },
    //   {
    //     id: "0fa5c6bc-5b58-40f5-a07e-d787e26d8b56",
    //     data: {
    //       gender: "F",
    //       sample_name: "Zebra",
    //       disease_status: "affected",
    //     },
    //     rels: {
    //       spouses: ["0c09cfa0-5e7c-4073-8beb-94f6c69ada19"],
    //       children: ["0"],
    //       father: "12a9bddf-855a-4583-a695-c73fa8c0e9b2",
    //       mother: "bd56a527-b613-474d-9f38-fcac0aae218b",
    //     },
    //   },
    //   {
    //     id: "ce2fcb9a-6058-4326-b56a-aced35168561",
    //     data: {
    //       gender: "M",
    //       sample_name: "Ben",
    //       disease_status: "affected",
    //     },
    //     rels: {
    //       mother: "8c92765f-92d3-4120-90dd-85a28302504c",
    //       father: "0",
    //       spouses: ["b4e33c68-20a7-47ba-9dcc-1168a07d5b52"],
    //       children: ["eabd40c9-4518-4485-af5e-e4bc3ffd27fb", "240a3f71-c921-42d7-8a13-dec5e1acc4fd"],
    //     },
    //   },
    //   {
    //     id: "f626d086-e2d6-4722-b4f3-ca4f15b109ab",
    //     data: {
    //       gender: "F",
    //       sample_name: "Becky",
    //       disease_status: "unaffected",
    //     },
    //     rels: {
    //       mother: "8c92765f-92d3-4120-90dd-85a28302504c",
    //       father: "0",
    //     },
    //   },
    //   {
    //     id: "eabd40c9-4518-4485-af5e-e4bc3ffd27fb",
    //     data: {
    //       gender: "M",
    //       sample_name: "Carlos",
    //       disease_status: "unaffected",
    //     },
    //     rels: {
    //       mother: "b4e33c68-20a7-47ba-9dcc-1168a07d5b52",
    //       father: "ce2fcb9a-6058-4326-b56a-aced35168561",
    //     },
    //   },
    //   {
    //     id: "b4e33c68-20a7-47ba-9dcc-1168a07d5b52",
    //     data: {
    //       gender: "F",
    //       sample_name: "Branka",
    //       disease_status: "affected",
    //     },
    //     rels: {
    //       spouses: ["ce2fcb9a-6058-4326-b56a-aced35168561"],
    //       children: ["eabd40c9-4518-4485-af5e-e4bc3ffd27fb", "240a3f71-c921-42d7-8a13-dec5e1acc4fd"],
    //     },
    //   },
    //   {
    //     id: "240a3f71-c921-42d7-8a13-dec5e1acc4fd",
    //     data: {
    //       gender: "F",
    //       sample_name: "HG002",
    //       disease_status: "affected",
    //     },
    //     rels: {
    //       mother: "b4e33c68-20a7-47ba-9dcc-1168a07d5b52",
    //       father: "ce2fcb9a-6058-4326-b56a-aced35168561",
    //     },
    //   },
    //   {
    //     id: "12a9bddf-855a-4583-a695-c73fa8c0e9b2",
    //     data: {
    //       gender: "M",
    //       sample_name: "HG003",
    //       disease_status: "unaffected",
    //     },
    //     rels: {
    //       children: ["0fa5c6bc-5b58-40f5-a07e-d787e26d8b56"],
    //       spouses: ["bd56a527-b613-474d-9f38-fcac0aae218b"],
    //     },
    //   },
    //   {
    //     id: "bd56a527-b613-474d-9f38-fcac0aae218b",
    //     data: {
    //       gender: "F",
    //       sample_name: "HG004",
    //       disease_status: "unaffected",
    //     },
    //     rels: {
    //       spouses: ["12a9bddf-855a-4583-a695-c73fa8c0e9b2"],
    //       children: ["0fa5c6bc-5b58-40f5-a07e-d787e26d8b56"],
    //     },
    //   },
    // ],
  }),
  computed: {
    pedigreeData() {
      return this.pedigreeDt.map((item) => {
        // item.data.gender = 'unknown'
        item.data.avatar = `/img/pedigree/pedigree-chart-${item.data.gender}-${item.data.disease_status}.png`;
        return item;
      });
    },
  },
  mounted() {
    const _this = this;
    getSamplePedigree(this.cohortId, this.projectId, this.sampleName, (res) => {
      // console.log(res)
      _this.pedigreeDt = res;
      _this.$nextTick(() => {
        _this.drawPedigree();
      });
    });
  },
  methods: {
    drawPedigree() {
      const store = f3.createStore({
          data: this.pedigreeData,
          node_separation: 200,
          level_separation: 80,
        }),
        view = f3.d3AnimationView({
          store,
          cont: document.querySelector("#FamilyChart"),
        }),
        Card = f3.elements.Card({
          store,
          svg: view.svg,
          card_dim: { w: 190, h: 50, text_x: 50, text_y: 15, img_w: 40, img_h: 40, img_x: 5, img_y: 5 },
          card_display: [(d) => `${d.data["sample_name"] || ""}`, (d) => `${d.data["disease_status"] || ""}`],
          mini_tree: true,
          link_break: false,
        });

      view.setCard(Card);
      store.setOnUpdate((props) => view.update(props || {}));
      store.update.tree({ initial: true });

      const paths = document.querySelectorAll("#FamilyChart path");
      paths.forEach(function (path) {
        path.setAttribute("stroke", "black");
      });

      const selectedSample = document.querySelectorAll(`[data-id='${this.sampleName}']`);
      selectedSample.forEach(function (sample) {
        sample.setAttribute("style", " -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)) !important; filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)) !important;");
      });

      const samples = document.querySelectorAll("#FamilyChart tspan");
      const _this = this;
      samples.forEach(function (sample) {
        if (sample.innerHTML == _this.sampleName) {
          sample.setAttribute("style", "font-weight:bold");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7)) !important;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7)) !important;
}
</style>