 <template>
  <div>
    <v-tooltip v-if="compact" right>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="text-decoration-underline" style="text-decoration-style: dotted !important">
          {{ omimInheritanceList }}
        </span>
      </template>
      <div v-for="(x, idx) in info || []" :key="idx.toString() + x.phenotype">
        <div>
          <b>{{ x.phenotype }}: </b> {{ x.inheritance }}
        </div>
      </div>
    </v-tooltip>
    <div v-else class="d-flex flex-row flex-wrap" >
      <div v-for="(x, idx) in info || []" :key="idx.toString() + x.phenotype" class="pa-1" style="min-width: 550px;">
        <span class="grey--text text--darken-1">{{ x.phenotype }}: </span> {{ x.inheritance }}
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  name: "OmimInheritaceList",
  props: {
    info: {
      type: Array,
      required: true,
    },
    compact: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    // omimInheritanceList: [],
  }),
  computed: {
    omimInheritanceList() {
      let inheritanceSet = new Set();
      if (this.info) {
        this.info.forEach((om) => {
          om.inheritance.split(",").forEach((inheritace) => {
            if (inheritace == "NA") {
              inheritanceSet.add("na");
            } else {
              inheritanceSet.add(
                inheritace
                  .replace("-", " ")
                  .replace("?", "")
                  .split(" ")
                  .map((m) => m.slice(0, 1).toUpperCase())
                  .join("")
              );
            }
          });
        });
      }
      return Array.from(inheritanceSet).join(" / ");
    },
  },
};
</script>