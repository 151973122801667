 <template>
  <v-menu offset-y left v-model="menu">
    <template v-slot:activator="{ on }">
      <div :class="customClass" v-on="on">
        <slot></slot>
      </div>
    </template>
    <v-card flat :elevation="0">
      <v-card-text class="d-flex flex-column">
        <div class="d-flex">
            <v-icon class="mr-1">mdi-help-circle-outline</v-icon>{{ message }}
        </div>
        <div class="ml-auto d-flex flex-row mt-1">
          <v-btn v-for="btn in buttons" :key="btn.val" @click="onAnswer(btn.val)" width="60px" class="mr-2" x-small outlined text :color="btn.color">{{ btn.val }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
 <script>
export default {
  name: "Confirm",
  data: () => ({
    menu: false,
  }),
  props: {
    message: {
      type: String,
      default: "Are you sure?",
    },
    customClass: {
      type: String,
      default: "",
    },
    buttons:{
      type: Array,
      default: ()=>[{val:'NO',color:'primary'}, {val:'YES',color:'red'}]
    }
  },
  methods: {
    setData(data) {
      this.data = data; // Store additional data
    },
    onAnswer(answer) {
      this.$emit("onAnswer", answer);
      this.menu = false;
    },
  },
};
</script>