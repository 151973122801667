import ApiIntegrface from "../api"
import store from '@/store'

export function saveCohortFilterSets(cohortId, name, filters, successMsg, callback, errhandler) {
    const api = new ApiIntegrface()
    const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/user-filters`
    const payload = {
        name: name,
        filters: filters
    }
    api.post(url, payload, callback, errhandler, successMsg)
}

export function updateCohortFilterSets(cohortId, filterId, name, filters, successMsg, callback, errhandler) {
    const api = new ApiIntegrface()
    const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/user-filters/${filterId}`
    const payload = {
        name: name,
        filters: filters
    }
    api.put(url, payload, callback, errhandler, successMsg)
}


export function getCohortFilterSets(cohortId, callback) {
    const api = new ApiIntegrface()
    const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/user-filters`
    api.get(url, null, callback)
}

export function deleteCohortFilterSets(cohortId, filterSetId,successMsg,  callback) {
    const api = new ApiIntegrface()
    const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/user-filters/${filterSetId}`
    api.delete(url, null, callback,null, successMsg)
}


export function getCohortCollabFilterSets(cohortId, callback) {
    const api = new ApiIntegrface()
    const url = `/api/v1/users/${store.state.UserId}/cohorts/${cohortId}/user-filters/collab`
    api.get(url, null, callback)
}

