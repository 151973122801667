<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row mt-6 mb-4">
      <div class="mr-auto ">
        <span class="font-weight-medium grey--text text--darken-3">Mark Sample as </span>
        <v-btn v-for="item in decisions"  :key="item.id" :color="item.color" :outlined="currentDecision != item.id" rounded class="mx-1 white--text" @click="onFinishAnalyis(item.id)"><v-icon class="mr-1">{{ item.icon }}</v-icon> {{ item.title }}</v-btn>
      </div>
  
      <v-btn color="primary" outlined class="mx-4" v-for="ds in datasets" :key="ds.id" @click="onDownload(ds.datasetType.name)"><v-icon class="mr-1">mdi-download-outline</v-icon> {{ ds.datasetType.name }} Analysis Report</v-btn>
    
    </div>
    <!-- <div class="caption mt-n3 grey--text text--darken-1">You will automatically be redirected to the next sample </div> -->
    <div v-if="allSampleAnalyzed" class="mx-auto mt-4 font-weight-medium">
      All the samples have been analyzed. 
      <router-link :to="{ name: 'LucidAllAnalysisCohortOverview', params: { id: projectId, cohortid: cohortId } }" class="text-decoration-none">

      Back to Overview</router-link>
      
    </div>
  </div>

</template>
<script>
import { updateDatasetSampleStatusAndDecision } from "@/api/user/dataset";
import { downloadAnalysisResultReport } from "@/api/user/dataset";
import { getSampleDecisions } from "@/api/dataset";
import loadSampleList from "@/mixins/lucidAllAnalysis/loadSampleList.js"

export default {
  name: "LucidAllAnalysisActions",
  mixins: [loadSampleList],
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    sampleName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    decisions: [],
    updateCount: 0,
    allSampleAnalyzed: false,
    projectId: null,
    selectedDecison: null,
  }),
  computed:{
    currentDecision(){
      if(this.selectedDecison != null){
        return this.selectedDecison
      } else {
        return this.samples.find(item => item.sampleName == this.sampleName)?.decision?.id || null
      }
    },

  },
  created() {
    // this.onDataLoadedCallback = this.onSamplesLoaded()
    this.projectId = this.$route.params.id
    const _this = this;
    this.loadSamples(this.projectId, this.cohortId)

    getSampleDecisions((res) => {
      _this.decisions = res.map((item) => {
        switch (item.id) {
          case 1:
            item["color"] = "green";
            item["icon"] = "mdi-check-all";
            break;
          case 2:
            item["color"] = "red";
            item["icon"] = "mdi-window-close";
            break;
          case 3:
            item["color"] = "orange";
            item["icon"] = "mdi-crosshairs-question";
            break;
        }
        return item
      });
    });
  },
  watch:{
    // updateCount(val){
    //   if( val == 2){
    //     let idx = this.samples.findIndex(item => item.sampleName == this.sampleName)
    //     idx++;
    //     if( idx < this.samples.length){
    //       const nextSampleName = this.samples[idx].sampleName
    //       this.$router.push({name:'LucidAllAnalysisSampleVariants', params:{id:this.projectId, cohortid:this.cohortId, sampleid:nextSampleName}});
    //       window.location.reload()
    //       // this.$router.refresh()
    //     } else {
    //       this.allSampleAnalyzed = true
    //     }
    //   }
    // }
  },
  methods: {

    onFinishAnalyis(decision) {
      const _this = this
      this.datasets.forEach((ds) => {
        updateDatasetSampleStatusAndDecision(this.cohortId, ds.id, this.sampleName, "DONE",decision, 
          `Analysis of sample ${this.sampleName} marked as FINISHED`,
          ()=>{
            _this.selectedDecison = decision
            _this.updateCount++;
          }
        );
      });
    },
    onDownload(dsType) {
      const _this = this;

      const dsId = this.datasets.find((ds) => ds.datasetType.name == dsType)?.id || null;

      downloadAnalysisResultReport(this.cohortId, dsId, this.sampleName, (res) => {
        let blob = new Blob([res], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = _this.sampleName + "-" + dsType + "-Analysis-" + ".pdf";
        link.click();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-menu__content {
  box-shadow: 0px 5px 5px -3px rgba(97, 27, 27, 0.2), 0px 8px 10px 1px rgba(133, 26, 26, 0.14), 0px 3px 14px 2px rgba(145, 13, 13, 0.12) !important;
}
</style>