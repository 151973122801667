 <template>
  <span>
    <v-tooltip bottom v-if="this.helpData" max-width="400" :open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <span v-if="hasSlot" v-on="on" v-bind="attrs" class="helpText" :class="type == 'text' ? 'primary--text text-decoration-underline dottecUnderline' : ''"><slot  ></slot></span>
        <v-icon v-else class="mb-1 helpText" x-small v-on="on" v-bind="attrs" color="primary lighten-2">mdi-help-circle-outline</v-icon>
      </template>
      <span v-html="helpData"></span>
    </v-tooltip>
  </span>
</template>
<script>
export default {
  name: "Help",
  props: {
    lookupKey: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    // hasSlot: false,
  }),
  created() {
    // this.hasSlot = !!this.$slots.default;
  },
  computed:{
    hasSlot(){
      return !!this.$slots.default
    },
    helpData(){
      const key1 = this.section + "__" + this.lookupKey;
      const key2 = this.section + "__" + this.lookupKey.toUpperCase();
      let data = this.$root.help[key1];
      if (data == undefined) {
        data = this.$root.help[key2];
      }
      return data
    }
  }
};
</script>
<style scoped>
.helpText:hover {
  cursor: help;
}

.dottecUnderline {
  text-decoration-style: dotted !important;
}
</style>