<template>
  <div>
    <v-rect :config="rectConfig"></v-rect>
    <v-text :config="textConfig"></v-text>
    <HighlightBar :startX="svHighlightInfo.start" :width="svHighlightInfo.width" :height="TRACK_HEIGHT" :baseLineY="baseLineY"></HighlightBar>
    <HighlightBar :startX="secondaryHighlightStart" :width="secondaryHighlightWidth" :height="TRACK_HEIGHT" :baseLineY="baseLineY"></HighlightBar>

    <DynamicInstant :tadInfo="tadInfo" v-for="(m, index) in trackInfo.instants" :key="index" :baseLineY="baseLineY + 15" :info="m" :textPosition="index % 2 == 0 ? 'top' : 'bottom'"></DynamicInstant>
    <v-line :config="lineConfig"></v-line>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import DynamicInstant from "@/components/defaultAnalysis/tad/DynamicInstant.vue";
import { toScatedX } from "@/utils/tad.js";
import HighlightBar from "@/components/defaultAnalysis/tad/HighlightBar.vue";

Vue.use(VueKonva);
export default {
  name: "DynamicInstantTrack",
  components: { DynamicInstant, HighlightBar },
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    trackInfo: {
      trackName: null,
      instants: [],
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    svHighlightInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedDynamicInstants: [],
    TRACK_HEIGHT: 51,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
  }),
  mounted(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },

  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
  },
  computed: {
    start() {
      return this.tadInfo.min;
    },
    end() {
      return this.tadInfo.max;
    },
    secondaryHighlightStart(){
      return this.toX(this.secondaryHightlightInfo.start)
    },
    secondaryHighlightWidth(){
      return this.showSecondaryHighlight? this.secondaryHightlightInfo.widthInPx : 0
    },    
    rectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY, width: this.toX(this.end), height: this.TRACK_HEIGHT, strokeWidth: 0, fill: "#F4D9EC50" };
    },
    textConfig() {
      return {
        x: 5,
        y: this.baseLineY + 2,
        // width: (this.info.end - this.info.start) *this.scaleFactor,
        text: this.trackInfo.trackName,
        fontSize: 10,
        fontStyle: "bold",
        fill: "#D595D7",
        // fill: "red",
        align: "left",
        // shadowColor:'black',
        // shadowBlur:3,
        // shadowOffset:3,
        // shadowOpacity:0.8
        // stroke:'red',
        // strokeWidth:0.5,
        // onMouseEnter: this.onMouseEnter,
        // onMouseLeave: this.onMouseLeave,
        // onClick: this.onMouseClick
      };
    },
    lineConfig() {
      return {
        x: 0,
        y: 0,
        points: [this.toX(this.start), this.baseLineY + this.TRACK_HEIGHT - 2, this.toX(this.end), this.baseLineY + this.TRACK_HEIGHT - 1],
        closed: true,
        stroke: "#FF85B7",
        strokeWidth: 1,
        opacity: 0.18,
        // fill: '#C585B7'
        dashEnabled: true,
        dash: [3, 3],
        fill: "#FF85B7",
      };
    },
  },
};
</script>