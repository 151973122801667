<template>
  <div>
    <v-line :config="intronConfig"></v-line>
    <!-- <v-rect v-for="ex in exonConfigs" :key="ex.start" :config="ex"></v-rect> -->
    <Exon v-for="ex in exons" :key="ex.start" :exon="ex" :exonHeight="exonHeight" :tadInfo="tadInfo" :spec="spec" :baseLineY="baseLineY"></Exon>
    <v-text   :config="textConfig"></v-text>
    <v-line ref="line" :config="arrowConfig"></v-line>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import { toScatedX } from "@/utils/tad.js";
// import { getTextSizeInPixel } from "@/utils/textUtils.js";
import Exon from "@/components/defaultAnalysis/tad/Transcripts/Exon.vue"
Vue.use(VueKonva);

export default {
  name: "Isoform",
  components: {Exon},
  data: () => ({
    height: 15,
    exonHeight: 7,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
  }),
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    spec: {
      blockCount: null,
      blockSizes: [],
      blockStarts: [],
      chr: null,
      direction: null,
      end: null,
      itemRgb: null,
      name: null,
      score: null,
      start: null,
      thickEnd: null,
      thickStart: null,
      isoform_id: null
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    dir: {
      type: String,
      default: null,
    },
    sv: {
      type: Object,
      default: () => ({}),
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
  },
  created(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },
  computed: {
    exons() {
      return this.spec.blockStarts.map((item, idx) => {
        let m = {};
        m["start"] = this.spec.start + item;
        m["end"] = this.spec.start + item + this.spec.blockSizes[idx];
        m["isHitBySv"] = this.sv.start  &&  this.sv.end >= m["start"] && this.sv.start <= m["end"]
        return m;
      });
    },
    exonConfigs() {
      return this.exons.map((ex) => {
        let color = "blue"
        if(
            (this.sv.start  &&  this.sv.end >= ex.start && this.sv.start <= ex.end)
            ||
            (this.showSecondaryHighlight &&  this.secondaryHightlightInfo.end >= ex.start && this.secondaryHightlightInfo.start <= ex.end)
        ){
          color = "red"
        }
        return {
          x: this.toX(ex.start),
          y: this.baseLineY - this.exonHeight / 2,
          width: this.toX(ex.end) - this.toX(ex.start),
          height: this.exonHeight,
          fill: color,
          strokeWidth: 1,
          stroke: color,
        };
      });
    },
    isAnyHit() {
      return this.exons.some((ex) => this.sv.start && this.sv.end >= ex.start && this.sv.start <= ex.end);
    },
    // tooltipRegion(){
    //     return {
    //         x: this.toX(this.spec.start) - 2,
    //         y: this.baseLineY + 10,
    //         width: this.toX(this.spec.end) - this.toX(this.spec.start) + 8  ,
    //         height: this.height,
    //     }
    // },
    isoformTitle(){
      return (this.spec?.isoform_id || this.spec.name)
    },
    textConfig() {
      return {
        x: this.spec.direction == "+" ? this.spec.xStart : this.toX(this.spec.end) +4 ,
        y: this.baseLineY -4,
        width: 200,
        text:  this.isoformTitle,
        fontSize: 10,
        fill: this.isAnyHit ? "red" : "#666666",
        align: "left",
      };
    },
    intronConfig() {
      return {
        x: 0,
        y: 0,
        points: [this.toX(Math.max(this.spec.start, this.tadInfo.min)), this.baseLineY, this.toX(Math.min(this.tadInfo.max, this.spec.end)), this.baseLineY],
        stroke: "grey",
        strokeWidth: 1,
      };
    },
    rectPoints() {
      let x = [this.toX(this.spec.start), this.baseLineY + 10, this.toX(this.spec.end) + 2, this.baseLineY + 10, this.toX(this.spec.end) + 2, this.baseLineY + 10 + this.height, this.toX(this.spec.start), this.baseLineY + 10 + this.height];
      if (this.spec.dir == "+") {
        x.splice(4, 0, this.toX(this.spec.end) + 2 + 3);
        x.splice(5, 0, this.baseLineY + 10 + this.height / 2);
      } else {
        x.push(this.toX(this.spec.start) - 3);
        x.push(this.baseLineY + 10 + this.height / 2);
      }
      return x;
    },
    rectConfig() {
      return {
        points: this.rectPoints,
        // strokeWidth:0 ,
        fill: this.spec.colorStr,
        closed: true,
        strokeWidth: 1,
        stroke: this.spec.affected ? "#888888" : "#FFFFFF",
        // dash:[2,2]
      };
    },
    arrowConfig() {
      const height = 6
      const width = 10
      let points = [];
      if (this.spec.direction == "+") {
        points.push(this.toX(this.spec.end) + 2);
        points.push(this.baseLineY - height/2);
        points.push(this.toX(this.spec.end) + 2 + width);
        points.push(this.baseLineY);
        points.push(this.toX(this.spec.end) + 2);
        points.push(this.baseLineY + height/2);
      } else {
        points.push(this.toX(this.spec.start)-2);
        points.push(this.baseLineY - height/2);
        points.push(this.toX(this.spec.start)-2);
        points.push(this.baseLineY + height/2);
        points.push(this.toX(this.spec.start )- 2 - width);
        points.push(this.baseLineY );
      }
      return {
        points: points,
        // strokeWidth:0 ,
        fill: "#777777",
        // fill: "white",
        closed: true,
        strokeWidth: 0.4,
        // stroke: "#777777",
        stroke: "black",
        // dash:[2,2]
      };
    },
  },
};
</script>