<template>
  <div ref="variantTabs">
    <div class="d-flex flex-row mb-2">
      <div v-if="title" class="text-h6 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-text-search-variant</v-icon>{{ title }}</div>
      <v-btn small text color="primary" class="ml-auto" @click="onExportResultClick"> <v-icon small class="mr-1">mdi-download-outline</v-icon>Download Result </v-btn>

      <ShareResult class="ml-2" :sampleId="projectId" :cohortId="cohortId" :filters="sharingFilters"> </ShareResult>
    </div>

    <v-tabs v-model="tab" color="primary" grow>
      <v-tab class="rounded-t-lg" active-class="rounded-t-lg grey lighten-3">SNVs</v-tab>
      <v-tab class="rounded-t-lg" active-class="rounded-t-lg grey lighten-3">SVs</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: #f0f0f0">
      <v-tab-item eager transition="slide-y-transition" ref="commonTabItems">
        <ViewVariantsTable
          ref="ViewVariantsTableSNV"
          :total="datasets.SNV.total"
          @onOptionsChanged="onOptionsChanged"
          :parentWidth="width"
          :loading="datasets.SNV.loading"
          :rawExploreResult="datasets.SNV.result"
          :fields="datasets.SNV.fields"
          :selectedDatabaseId="datasets.SNV.info.id "
          :selectedUsersGenes="datasets.SNV.userSelectedGenes"
          databaseType="SNV"
          :cohortId="cohortId"
        >
        </ViewVariantsTable>
      </v-tab-item>
      <v-tab-item eager transition="slide-y-transition" ref="commonTabItems">
        <ViewVariantsTable
          ref="ViewVariantsTableSV"
          :total="datasets.SV.total"
          @onOptionsChanged="onOptionsChanged"
          :parentWidth="width"
          :loading="datasets.SV.loading"
          :rawExploreResult="datasets.SV.result"
          :fields="datasets.SV.fields"
          :selectedDatabaseId="datasets.SV.info.id"
          :selectedUsersGenes="datasets.SV.userSelectedGenes"
          databaseType="SV"
          :cohortId="cohortId"
        >
        </ViewVariantsTable>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { getData } from "@/api/user/dataset";
import { getFieldGroups } from "@/api/settings";
import exportToExcel from "@/mixins/exportToExcel.js";

import { getDatasetsBySample } from "@/api/dataset.js";
import { getDatasetFields } from "@/api/dataset";

import ShareResult from "@/components/defaultAnalysis/ShareResult.vue";
import ViewVariantsTable from "@/components/ViewVariantsTable.vue";
export default {
  name: "ViewFilteredVariants",
  components: { ShareResult, ViewVariantsTable },
  mixins: [exportToExcel],
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    title:{
        type:String,
        default: 'Results'
    }
  },
  data: () => ({
    tab: 0,
    datasets: {
      SNV: {
        info: {
            id: -1
        },
        result: [],
        fields: [],
        filters: null,
        variantLabels: null,
        loading: true,
        total: 0,
        userSelectedGenes: [],
        resultToExport: null,
      },
      SV: {
        info: {
            id: -1
        },
        result: [],
        fields: [],
        filters: null,
        variantLabels: null,
        loading: true,
        total: 0,
        userSelectedGenes: [],
        resultToExport: null,
      },
    },
    lastAppliedFilters: {
      SV: {},
      SNV: {},
    },
    fieldGroups: [],
    width: 0,
    init:false

  }),
  computed: {
    sharingFilters() {
      return { sv_filters: this.lastAppliedFilters.SV, snv_filters: this.lastAppliedFilters.SNV };
    },
  },
  created() {
    const _this = this;
    getFieldGroups(function (res) {
      _this.fieldGroups = res;
      getDatasetsBySample(_this.projectId, (res) => {
          _this.datasets.SV.info = res.find((item) => item.datasetType.name == 'SV');
          _this.datasets.SNV.info = res.find((item) => item.datasetType.name == 'SNV');
          getDatasetFields(_this.datasets.SV.info.id, (rs) => {
              _this.datasets.SV.fields = rs;
              getDatasetFields(_this.datasets.SNV.info.id, (rs) => {
                _this.datasets.SNV.fields = rs;
                _this.onFilterSet();
              });
          });
      });
    });
  },
  methods: {
    onExportResultClick() {
      this.loadData("SV", 1, 10000, this.lastAppliedFilters["SV"].sort, true);
      this.loadData("SNV", 1, 10000, this.lastAppliedFilters["SNV"].sort, true);
    },

    onOptionsChanged(options, dsType) {
      // console.log('onOptionsChanged',options, dsType)
      var sort = options.sortBy.map((item, index) => {
        let o = {};
        o[this.datasets[dsType].fields.find((x) => x.title == item).name] = { order: options.sortDesc[index] ? "desc" : "asc" };
        return o;
      });
      this.loadData(dsType, options.page, options.itemsPerPage, sort);
    },
    onFilterSet() {
      const filters = this.filters;
      // console.log(filters)
    //   console.log(this.datasets)
      this.$nextTick(() => {
        this.width = this.$refs.variantTabs.clientWidth;
      });
      Object.keys(this.datasets).forEach((dsType) => {
        this.$set(this.datasets[dsType], "result", []);
        this.$set(this.datasets[dsType], "total", 0);
        this.$set(this.datasets[dsType], "loading", true);
        this.$set(this.datasets[dsType], "filters", filters.filters[dsType]);
        this.$set(this.datasets[dsType], "variantLabels", filters.variantLabels[dsType]);
        this.$set(this.datasets[dsType], "collabLabels", filters.collabLabelsPerDs[dsType]);

        if (this.$refs[`ViewVariantsTable${dsType}`]) {
          this.$refs[`ViewVariantsTable${dsType}`].reset();
        }

        const geneFieldName = this.datasets[dsType].fields.find((item) => item.title == "OVERLAPPING_GENES").name;
        const geneFilter = filters.filters[dsType].inFilters.find((item) => Object.keys(item)[0] == geneFieldName);
        if (geneFilter) {
          this.$set(this.datasets[dsType], "userSelectedGenes", geneFilter[geneFieldName]);
        } else {
          this.$set(this.datasets[dsType], "userSelectedGenes", []);
        }

        this.loadData(dsType);
      });
    },
    loadData(dsType, page = 1, itemsPerPage = 10, sort = [], isExporting = false) {
      const _this = this;
      const columnGroups = this.fieldGroups.filter((g) => g.groupType === "VIEW");
      const selectedColumnGroups = Object.fromEntries(columnGroups.map((item) => [item.id, true]));
      const groups = Object.entries(selectedColumnGroups)
        .filter((item) => item[1] == true)
        .map((x) => Number(x[0]));
      if (groups.length == 0) {
        _this.$root.notify.show({ message: "At least one column group should be selected!", type: "warning" });
        return;
      }

      // console.log('userSelectedGenes',userSelectedGenes)
      // this.selectedUsersGenes = [];

      if (sort.length == 0) {
        const idField = this.datasets[dsType].fields.find((f) => f.title == "ID").name;
        let sortById = {};
        sortById[idField] = { order: "asc" };
        sort = [sortById];
      }
      let rangeFilters = this.datasets[dsType].filters.rangeFilters
      if(rangeFilters === undefined){
        rangeFilters = [...this.datasets[dsType].filters.rangeFromfilters, ...this.datasets[dsType].filters.rangeTofilters, ...this.datasets[dsType].filters.rangeBetweenfilters];
      }
      // const varialeLabels = this.datasets[dsType].variantLabels.map(item => { this.variantLabels.find(x => x.title==item).id })

      this.lastAppliedFilters[dsType] = {
        cohortId: this.cohortId,
        datasetId: this.datasets[dsType].info.id,
        rangeFilters: rangeFilters,
        inFilters: this.datasets[dsType].filters.inFilters,
        notFilters: this.datasets[dsType].filters.notFilters,
        exactFilters: this.datasets[dsType].filters.exactFilters,
        groups: groups,
        variantAnnotationLabels: this.datasets[dsType].variantLabels,
        collaborators_labels: this.datasets[dsType].collabLabels,
        columnTitles: ["SAMPLE", "ID"],
        variantLabelstoExclude: [],
        column_group_membership: "one_of_all",
        sort: sort,
        userSelectedGenes: this.datasets[dsType].userSelectedGenes,
      };
      getData(
        this.cohortId,
        this.datasets[dsType].info.id,
        this.datasets[dsType].filters.exactFilters,
        rangeFilters,
        this.datasets[dsType].filters.inFilters,
        this.datasets[dsType].filters.notFilters,
        groups,
        "one_of_all",
        itemsPerPage,
        page - 1,
        sort,
        this.datasets[dsType].variantLabels,
        [],
        ["SAMPLE", "ID"],
        this.datasets[dsType].collabLabels,
        function (res) {
          if (isExporting) {
            _this.$set(_this.datasets[dsType], "resultToExport", res.result);
            if (_this.datasets.SV.resultToExport !== null && _this.datasets.SNV.resultToExport !== null) {
              _this.exportToExcel(_this.datasets.SV.info.sample.name, [
                { name: "SV", data: _this.datasets.SV.resultToExport },
                { name: "SNV", data: _this.datasets.SNV.resultToExport },
              ]);
              _this.datasets.SV.resultToExport = null;
              _this.datasets.SNV.resultToExport = null;
            }
          } else {
            _this.$set(_this.datasets[dsType], "result", res.result);
            _this.$set(_this.datasets[dsType], "total", res.total);
            _this.$set(_this.datasets[dsType], "loading", false);
          }
        }
      );
    },
  },
};
</script>