<template>
  <v-rect :config="highlightRectConfig"></v-rect>
</template>
<script>
// import { toScatedX } from '@/utils/tad.js'

export default {
  name: "HighlightBar",
  props: {
    startX: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    baseLineY: {
      type: Number,
      required: true,
    },
  },
  computed: {
    highlightRectConfig() {
      return {
        // x: this.toX(this.start),
        x: this.startX,
        y: this.baseLineY -2,
        // width: this.toX(this.end) - this.toX(this.start),
        width: this.width,
        height: this.height +2,
        strokeWidth: 0,
        fill: "#55555530",
      };
    },
  },
};
</script>