<template>
  <div style="min-height: 55px" class="white px-1">
    <div class="d-flex flex-row mx-2 pt-2 pl-2 mt-4">
      <div class="text-h6 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-blur-linear</v-icon>Regulome</div>
      <v-btn color="primary" class="mt-2 ml-auto" text x-small @click="onShowHideRegulome()"> {{ isHidden ? "Show Regulome" : "Hide Regulome" }} <v-icon small>{{ isHidden ? "mdi-chevron-down" : "mdi-chevron-up" }} </v-icon> </v-btn>
    </div>
    <CohortTadChart
      ref="refrenceTad"
      v-if="tads.length > 0 && !isHidden"
      :chromosome="chromosome"
      :tads="tads"
      :geneEnhancers="geneEnhancers"
      :dynamicTracks="dynamicTracks"
      :isoforms="coolboxData.transcripts"
      :svs="SVs"
      :snvs="SNVs"
      :name="gene"
      @onMinMax="onMinMax"
    ></CohortTadChart>
  </div>
</template>
<script>
import CohortTadChart from "@/components/defaultAnalysis/tad/CohortTadChart.vue";
import { getProjectGeneRegulome } from "@/api/user/cohort";

export default {
  name: "Cohortcoolbox",
  components: { CohortTadChart },
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    gene: {
      type: String,
      required: true,
    },
    samples: {
      type: Array,
      required: true,
    },
    chromosome: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      default: null,
    },
    regulomeCollapsed:{
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    coolboxData: {
      tads: [],
      connections: [],
      dynamic_tracks: [],
      enhancers: [],
      genes: [],
      transcripts: [],
      SVs: [],
      SNVs: [],
    },
    isHidden: false,
  }),
  computed: {
    SVs() {
      return this.coolboxData.SVs.map((item) => {
        let lowerCasedItem = {};
        Object.keys(item).forEach((k) => (lowerCasedItem[k.toLowerCase()] = item[k]));
        return lowerCasedItem;
      });
    },
    SNVs() {
      return this.coolboxData.SNVs.map((item) => {
        let lowerCasedItem = {};
        Object.keys(item).forEach((k) => (lowerCasedItem[k.toLowerCase()] = item[k]));
        return lowerCasedItem;
      });
    },
    // pedigreeSvs() {
    //   return this.coolboxData.pedigree_svs;
    // },
    tads() {
      return this.coolboxData.tads.map((item) => {
        let t = item;
        t["id"] = item.name;
        t["chromosome"] = item.chr;
        return t;
      });
    },
    geneEnhancers() {
      // console.log("geneEnhancers:180", this.coolboxData.genes);
      let genes = this.coolboxData.genes.map((item) => {
        let g = item;
        g["id"] = g.name;
        g["chromosome"] = g.chr;
        g["dir"] = g.direction;
        g["type"] = "GENE";
        g["userSelected"] = this.gene.toUpperCase() == g.name.toUpperCase();
        g["differentiallyExpressed"] = false; //this.differentiallyExpressedGenes.indexOf(g.name.toUpperCase()) >= 0;
        return g;
      });
      let enhancers = this.coolboxData.enhancers.map((item) => {
        let e = item;
        e["id"] = e.name;
        e["chromosome"] = e.chr;

        e["dir"] = e?.direction || null;
        e["type"] = "RE";
        return e;
      });
      let connections = this.coolboxData.connections.map((c) => {
        return { from: c.enhancer, to: c.gene, color: 3 };
      });
      return { elements: genes.concat(enhancers), connections: connections };
    },
    dynamicTracks() {
      const tracks = Array.from(new Set(this.coolboxData.dynamic_tracks.map((item) => item.track_name)));
      // const tracks = t.concat(t)
      return tracks.map((t) => {
        let x = { trackName: t };
        x["instants"] = this.coolboxData.dynamic_tracks
          .filter((item) => item.track_name == t)
          .map((i) => {
            return {
              name: i.element_name,
              start: i.start,
              end: i.end,
              color: i.color,
              dir: i.direction,
              info: i.info,
            };
          });
        return x;
      });
    },
  },
  methods: {
    onShowHideRegulome() {
      this.isHidden = !this.isHidden;
      this.$emit('onRegulomeVisibilityChanged', this.isHidden)
    },
    onMinMax(minMax) {
      this.$emit("onMinMax", minMax);
    },
  },
  watch:{
    regulomeCollapsed(val){
      this.isHidden = val
    }
  },
  created() {
    this.isHidden = this.regulomeCollapsed
    const _this = this;
    let payload = {};
    payload["chr"] = this.chromosome.id;
    if (this.filters !== null) {
      payload["sv_filters"] = this.filters.SV;
      payload["snv_filters"] = this.filters.SNV;
    } else {
      payload["samples"] = this.samples;
    }
    getProjectGeneRegulome(this.cohortId, this.projectId, this.gene, payload, (res) => {
      _this.coolboxData = res;
    });
  },
};
</script>
<style scoped>

.tadBody {
  background-color: #f5f5f5;
  border-bottom: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 0 0 8px 8px;
}
.tadHeader {
  border-top: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 8px 8px 0 0;
}

</style>