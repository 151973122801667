<template>
    <div>
        <v-line  :config="rectConfig"></v-line>
        <!-- <v-text :config="textConfig" ></v-text> -->
        <Tooltip   :region="tooltipRegion" :text="spec.id +': ' +spec.info" ></Tooltip> 

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'
import { toScatedX } from '@/utils/tad.js'

Vue.use(VueKonva);

export default {
    name:'RE',
    components:{Tooltip},

    data:()=>({
        height:9,
    }),
    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        spec:{
            id: null,
            start: null,
            end: null,
            dir: null,
            info: null,
            color: null,
            colorStr: null,
        },
        baseLineY:{
            type: Number,
            default:500
        }
        
    },
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
    },
    computed:{
        tooltipRegion(){
            return {
                x: this.toX(this.spec.start) -5,
                y: this.baseLineY + 10, 
                width: this.toX(this.spec.end) - this.toX(this.spec.start) +10, 
                height: this.height,
            }         
        },
        textConfig(){
            return {
                x: this.toX(this.spec.start) - 20,
                y: this.baseLineY + 28, 
                width: this.toX(this.spec.end) - this.toX(this.spec.start)  + 40, 
                text: this.spec.id, 
                fontSize: 11, 
                fill:'#666666',
                // stroke:'#111111', 
                // fill: 'black',
                align:'center'
            } 
        },

        rectPoints(){
            let x = [
                    this.toX(this.spec.start),
                    this.baseLineY + 10,
                    this.toX(this.spec.end),
                    this.baseLineY + 10,
                    this.toX(this.spec.end),
                    this.baseLineY + 10 + this.height,
                    this.toX(this.spec.start),
                    this.baseLineY + 10 + this.height
                ] 
            if (this.spec.dir == '+'){
                x.splice(4,0, (this.toX(this.spec.end) + 2) )
                x.splice(5,0,this.baseLineY + 10 +this.height/2)
                x.push(this.toX(this.spec.start+2) )
                x.push(this.baseLineY + 10 +this.height/2)


            } else {
                x.splice(4,0,this.toX(this.spec.end) - 2)
                x.splice(5,0,this.baseLineY + 10 +this.height/2)
                x.push(this.toX(this.spec.start)-2) 
                x.push(this.baseLineY + 10 +this.height/2)

            }
            return x
        },
        rectConfig(){
            return {
                points:this.rectPoints,
                fill: this.spec.colorStr,
                closed: true,
                strokeWidth:3 ,
                // stroke:'#888888', 
                stroke: (this.spec.isHit? 'red': '#888888' )  , 

                // dash:[2,2]
            }


        }
        
    }
    
}
</script>