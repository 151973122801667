import { render, staticRenderFns } from "./TadSnvCoolbox.vue?vue&type=template&id=2758d543&scoped=true"
import script from "./TadSnvCoolbox.vue?vue&type=script&lang=js"
export * from "./TadSnvCoolbox.vue?vue&type=script&lang=js"
import style0 from "./TadSnvCoolbox.vue?vue&type=style&index=0&id=2758d543&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2758d543",
  null
  
)

export default component.exports