<template>
  <div class="d-flex flex-column">
    <div v-show="showFilters">
      <div class="d-flex flex-row">
        <v-tabs v-model="tab" background-color="transparent" color="primary">
          <v-tab class="rounded-t-lg" active-class="grey lighten-3">Common Filters</v-tab>
          <v-tab class="rounded-t-lg" active-class="grey lighten-3" :disabled="snvFilterFields.length == 0">SNV Specific</v-tab>
          <v-tab class="rounded-t-lg" active-class="grey lighten-3" :disabled="svFilterFields.length == 0">SV Specific</v-tab>
        </v-tabs>
        <div class="d-flex flex-row justify-end">
          <ShowFilterSets :cohortId="cohortId" :filters="filtersToSave" @onLoadFilter="onLoadUserFilterSet"></ShowFilterSets>
          <AddFilterSet :cohortId="cohortId" :filters="filtersToSave"></AddFilterSet>
        </div>
      </div>
      <v-tabs-items v-model="tab" style="background-color: #f0f0f0" @change="setMaxTabItemHeight">
        <v-tab-item transition="slide-y-transition" ref="commonTabItems" :style="{ minHeight: tabMaxHeight }">
          <div class="d-flex flex-wrap ma-4">
            <v-card color="transparent" v-show="showFilters" v-for="prp in commonFilterFields" :key="prp.title" width="320px" class="px-3 mb-1" flat>
              <FilterInput
                :type="prp.filterType"
                :title="prp.title"
                :key="'__' + prp.title"
                :items="prp.filterType == 'CATEGORICAL' ? prp.aggs : null"
                :stats="['RANGE_FROM', 'RANGE_TO', 'RANGE_BETWEEN'].includes(prp.filterType) ? prp.aggs : null"
                :datatype="prp.datatype"
                :hint="prp.hint"
                @inputType="setFilterInputType"
                :value="selectedProperties[prp.title]"
                :filterLogic="selectedPropertiesLogic[prp.title] || false"
                :reversible="reversibleCategorical"
                :description="extraDescription[prp.title]?.desc || null"
                @input="(v, r) => onFilterInput(prp.title, v, r)"
              ></FilterInput>
            </v-card>
            <v-card v-if="showVariantLabelFilter" color="transparent" v-show="showFilters" width="320px" class="px-3 mb-4" flat>
              <FilterInput :reversible="false" type="CATEGORICAL" title="Your Label" :items="variantLabels.map((item) => item.title)" hint="" v-model="selectedVariantLabel"></FilterInput>
            </v-card>
            <v-card v-if="showVariantLabelFilter && coworkerLabels.length > 0" color="transparent" v-show="showFilters" width="640px" class="px-3 mb-4" flat>
              <FilterInput :reversible="false" type="CATEGORICAL" :title="`${organizationName} Users' Label`" :items="coworkerLabels" hint="" v-model="selectedCollabLabel"></FilterInput>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item transition="slide-y-transition" ref="snvTabItems" :style="{ minHeight: tabMaxHeight }">
          <div class="d-flex flex-wrap ma-4">
            <v-card color="transparent" v-show="showFilters" v-for="prp in snvFilterFields" :key="prp.title" width="320px" class="px-3 mb-1" flat>
              <FilterInput
                :type="prp.filterType"
                :title="prp.title"
                :key="'__' + prp.title"
                :items="prp.filterType == 'CATEGORICAL' ? prp.aggs : null"
                :stats="['RANGE_FROM', 'RANGE_TO', 'RANGE_BETWEEN'].includes(prp.filterType) ? prp.aggs : null"
                :datatype="prp.datatype"
                :hint="prp.hint"
                @inputType="setFilterInputType"
                :filterLogic="selectedPropertiesLogic[prp.title] || false"
                :value="selectedProperties[prp.title]"
                :reversible="reversibleCategorical"
                :description="extraDescription[prp.title]?.desc || null"
                @input="(v, r) => onFilterInput(prp.title, v, r)"
              ></FilterInput>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item transition="slide-y-transition" ref="svTabItems" :style="{ minHeight: tabMaxHeight }">
          <div class="d-flex flex-wrap ma-4">
            <v-card color="transparent" v-show="showFilters" v-for="prp in svFilterFields" :key="prp.title" width="320px" class="px-3 mb-1" flat>
              <FilterInput
                :type="prp.filterType"
                :title="prp.title"
                :key="'__' + prp.title"
                :items="prp.filterType == 'CATEGORICAL' ? prp.aggs : null"
                :stats="['RANGE_FROM', 'RANGE_TO', 'RANGE_BETWEEN'].includes(prp.filterType) ? prp.aggs : null"
                :datatype="prp.datatype"
                :hint="prp.hint"
                @inputType="setFilterInputType"
                :filterLogic="selectedPropertiesLogic[prp.title] || false"
                :value="selectedProperties[prp.title]"
                :reversible="reversibleCategorical"
                :description="extraDescription[prp.title]?.desc || null"
                @input="(v, r) => onFilterInput(prp.title, v, r)"
              ></FilterInput>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <slot name="extraFilters"></slot>
    <SelectedFilterChipsList :selectedPropertiesLabels="selectedPropertiesLabels" :enableCloseChips="showFilters" @onClearFilter="clearFilter"></SelectedFilterChipsList>
    <!-- <div class="d-flex flex-row">
      <div v-if="selectedPropertiesLabels.length > 0" class="d-flex justify-start flex-wrap rounded-lg mt-2 px-1 mb-1" style="min-height: 50px">
        <template v-for="item in selectedPropertiesLabels">
          <v-tooltip :key="item.id" bottom max-width="400" :open-delay="300" :disabled="item.truncatedText == null">
            <template v-slot:activator="{ on, attrs }">
              <v-chip :key="item.id" class="ma-2 pl-4" v-bind="attrs" v-on="on" :close="showFilters" color="primary lighten-1" rounded="0" label outlined @click:close="clearFilter(item.id)">
                <span v-html="item.truncatedText != null ? item.truncatedText : item.fullText"></span>
              </v-chip>
            </template>
            <span v-html="item.fullText"> </span>
          </v-tooltip>
        </template>
      </div>
    </div> -->
    
    <v-btn v-if="showFilters" elevation="0" color="primary" class="mx-auto px-10 mb-2 mt-2" @click="onExplore()">
      {{ searchButtonTitle }}
      <v-icon class="ml-2">{{ searchButtonIcon }}</v-icon>
    </v-btn>
  </div>
</template> 
  <script>
// import { getDatasetFieldsByGroup } from "@/api/dataset";
import { getDatasetFieldsByOneOfGroups } from "@/api/dataset";
import { getDistincts } from "@/api/user/dataset";
import { getFieldGroups } from "@/api/settings";
import FilterInput from "@/components/defaultAnalysis/filters/FilterInput.vue";
import AddFilterSet from "@/components/defaultAnalysis/filters/AddFilterSet.vue";
import ShowFilterSets from "@/components/defaultAnalysis/filters/ShowFilterSets.vue";

import SelectedFilterChipsList from "@/components/defaultAnalysis/filters/SelectedFilterChipsList";
import { getVariantLabels } from "@/api/settings";
import { getCoworkers } from "@/api/users";
export default {
  name: "DefaultAnalysisFilters",
  components: { FilterInput, AddFilterSet, SelectedFilterChipsList, ShowFilterSets },
  props: {
    selectedDatabases: {
      type: Array,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    filterGroupName: {
      type: String,
      default: "FILTER",
    },
    searchButtonTitle: {
      type: String,
      default: "Search",
    },
    searchButtonIcon: {
      type: String,
      default: "mdi-magnify",
    },
    saveFilters: {
      type: Boolean,
      default: true,
    },
    saveKeyName: {
      type: String,
      default: "variantExplorer",
    },
    showVariantLabelFilter: {
      type: Boolean,
      default: true,
    },
    reversibleCategorical: {
      type: Boolean,
      default: true,
    },
    defaultFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    extraDescription: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    tab: 0,
    fieldGroups: null,
    // columnGroups: null,
    variantLabels: [],
    selectedVariantLabel: [],
    collaborators: [],
    selectedCollabLabel: [],
    datasetFilterFields: {},
    filterGroups: null,
    selectedProperties: {},
    selectedPropertiesLogic: {},
    filterFields: {},
    selectedGenesType: [],
    projectId: null,
    mergedFilterFields: [],
    tabMaxHeight: "110px",
    filterSetName: null,
    // fieldDescriptions:{}

    // datasetViewFields: null
  }),
  computed: {
    organizationName() {
      return this.$store.state.Organization.name;
    },
    coworkerLabels() {
      let res = [];
      this.collaborators.forEach((u) => {
        this.variantLabels.forEach((l) => {
          res.push(`${u.first_name} ${u.last_name}: ${l.title}`);
        });
      });
      return res;
    },
    commonFilterFields() {
      return this.mergedFilterFields.filter((item) => item.hint == null);
    },
    svFilterFields() {
      return this.mergedFilterFields.filter((item) => item.hint == "SV");
    },
    snvFilterFields() {
      return this.mergedFilterFields.filter((item) => item.hint == "SNV");
    },
    // mergedFilterFields() {
    //   return this.mergeFilterFields(this.selectedDatabases, this.filterFields);
    // },
    effectiveSelectedProperties() {
      let r = {};
      Object.entries(this.selectedProperties).forEach((x) => {
        if (this.isValidProp(x[1])) {
          r[x[0]] = x[1];
        }
      });
      return r;
    },
    selectedPropertiesLabels() {
      let re = Object.entries(this.effectiveSelectedProperties).map((prop) => {
        const fld = this.mergedFilterFields.find((item) => item.title == prop[0]);
        if (fld == undefined) {
          console.log("ERRR: MEREGED_FIELDS:", this.mergedFilterFields, "Prop:", prop);
        }
        let p = { id: prop[0], name: fld.title, operator: "=", rawValue: prop[1], truncatedText: null };
        let value = null;
        if (Array.isArray(prop[1])) {
          value = prop[1].map((item) => {
            const x = isNaN(item) || item == null ? item : new Intl.NumberFormat("en-US", { maximumFractionDigits: 4 }).format(item);
            return x;
          });
        } else {
          value = isNaN(prop[1]) || prop[1] == null ? prop[1] : new Intl.NumberFormat("en-US", { maximumFractionDigits: 4 }).format(prop[1]);
        }
        // console.log(prop[1], value)
        if (fld.filterType === "GENE") {
          p.operator = "in";
          p.value = " [" + value.slice(0, 10).join(" ,") + (value.length > 10 ? ",..." : "") + "] " + this.selectedGenesType;
          const all = "[" + value.join(" ,") + "] " + this.selectedGenesType;
          p.fullText = `<b class="pr-1">${p.name} </b> ${p.operator} ${all}`;
          if (value.length > 10) {
            const truncated = "[" + value.slice(0, 10).join(" ,") + ", ...] " + this.selectedGenesType;
            p.truncatedText = `<b class="pr-1">${p.name} </b> ${p.operator} ${truncated} `;
          }
        } else if (fld.filterType === "CATEGORICAL") {
          if (prop[1].length > 1) {
            p.value = " [" + prop[1].join(" ,") + "] ";
            if (this.selectedPropertiesLogic[p.name] !== undefined && this.selectedPropertiesLogic[p.name] == true) {
              p.operator = "is not one of";
            } else {
              p.operator = "is one of";
            }
          } else {
            p.value = prop[1][0];
            if (this.selectedPropertiesLogic[p.name] !== undefined && this.selectedPropertiesLogic[p.name] == true) {
              p.operator = "≠";
            } else {
              p.operator = "=";
            }
          }
          p.fullText = `<b class="pr-1">${p.name} </b> ${p.operator} ${p.value}`;
          if (prop[1].length > 5) {
            const truncated = "[" + prop[1].slice(0, 5).join(" ,") + ", ...]";
            p.truncatedText = `<b class="pr-1">${p.name} </b> ${p.operator} ${truncated} `;
          }
        } else if (fld.filterType === "RANGE_BETWEEN") {
          if (this.isValidProp(value[0]) && this.isValidProp(value[1])) {
            if (value[0] == value[1]) {
              p.fullText = `<b class="pr-1">${p.name} </b> = ${value[1]}`;
            } else {
              p.fullText = `${value[0]} ≤ <b class="pr-1">${p.name} </b> ≤ ${value[1]}`;
            }
          } else if (this.isValidProp(value[0])) {
            p.fullText = ` <b class="pr-1">${p.name} </b> ≥ ${value[0]}`;
          } else if (this.isValidProp(value[1])) {
            p.fullText = `<b class="pr-1">${p.name} </b> ≤ ${value[1]}`;
          }
          p.value = "[ " + (value[0] ? value[0] : "-∞") + " , " + (value[1] ? value[1] : "+∞") + " ] ";
          p.operator = "in";
        } else if (fld.filterType === "RANGE_FROM") {
          p.value = value;
          p.operator = "≥";
          p.fullText = ` <b class="pr-1">${p.name} </b> ≥ ${value}`;
        } else if (fld.filterType === "RANGE_TO") {
          p.value = value;
          p.operator = "≤";
          p.fullText = `<b class="pr-1">${p.name} </b> ≤ ${value}`;
        } else {
          p.operator = "=";
          p.fullText = `<b class="pr-1">${p.name} </b> = ${value}`;
          p.value = value;
        }
        return p;
      });

      if (this.selectedVariantLabel.length > 0) {
        const v = " [" + this.selectedVariantLabel.join(" ,") + "] ";
        re.push({
          id: "__user_label",
          name: "__user_label",
          value: v,
          operator: "is one of",
          fullText: `<b class="pr-1"> User Label</b> is one of ${v}`,
        });
      }
      if (this.selectedCollabLabelPerUser.length > 0) {
        const users = Array.from(new Set(this.selectedCollabLabelPerUser.map((item) => item.user_id)));
        let v = "[ ";
        users.forEach((u) => {
          const usr = this.collaborators.find((c) => c.id == u);
          v +=
            "<i>" +
            usr.first_name +
            " " +
            usr.last_name +
            "</i>: { " +
            this.selectedCollabLabelPerUser
              .filter((sc) => sc.user_id == u)
              .map((sc) => this.variantLabels.find((l) => l.id == sc.label_id).title)
              .join(",") +
            " } ";
        });
        v += " ]";
        // const v = " [" + this.selectedVariantLabel.join(" ,") + "] "
        re.push({
          id: "__collab_label",
          name: "__collab_label",
          value: v,
          operator: "is one of",
          fullText: `<b class="pr-1"> ${this.organizationName} Users' Label</b> is one of ${v}`,
        });
      }
      return re;
    },

    selectedCollabLabelPerUser() {
      return this.selectedCollabLabel.map((item) => {
        let x = {
          user_id: this.collaborators.find((u) => u.first_name + " " + u.last_name == item.substr(0, item.indexOf(":"))).id,
          label_id: this.variantLabels.find((l) => l.title == item.substr(item.indexOf(":") + 2)).id,
        };
        return x;
      });
    },

    filtersToSave() {
      let filtersPerDs = {};
      let variantLabelsPerDs = {};
      let collabLabelsPerDs = {};
      this.selectedDatabases.forEach((ds) => {
        filtersPerDs[ds.datasetType.name] = this.getFilters(ds.datasetType.name);
        variantLabelsPerDs[ds.datasetType.name] = this.selectedVariantLabel.map((lblTitle) => this.variantLabels.find((x) => x.title == lblTitle).id);
        collabLabelsPerDs[ds.datasetType.name] = this.selectedCollabLabelPerUser;
      });
      let toSave = {
        effectiveSelectedProperties: this.effectiveSelectedProperties,
        selectedPropertiesLogic: this.selectedPropertiesLogic,
        filtersLabels: this.selectedPropertiesLabels,
        filtersPerDs: filtersPerDs,
        variantLabelsPerDs: variantLabelsPerDs,
        collabLabelsPerDs: collabLabelsPerDs,
      };
      return toSave;
    },
  },
  created() {
    this.projectId = this.$route.params.id;
    const _this = this;
    getVariantLabels((res) => {
      _this.variantLabels = res;
    });
    getCoworkers((res) => {
      _this.collaborators = res;
    });
    getFieldGroups(function (res) {
      _this.fieldGroups = res;
      _this.loadDatasetFields(() => {
        //set default filters OR already set filters
        if (Object.keys(_this.filterFields).length == _this.selectedDatabases.length) {
          // console.log(Object.keys(_this.filterFields).length , _this.selectedDatabases.length)
          // console.log('_this.mergeFilterFields1', _this.mergedFilterFields)
          _this.mergedFilterFields = _this.mergeFilterFields(_this.selectedDatabases, _this.filterFields);
          if (_this.saveFilters) {
            const pref = _this.$store.state.UserPreferences;
            if (pref && pref[_this.saveKeyName] && pref[_this.saveKeyName][_this.projectId] && pref[_this.saveKeyName][_this.projectId].filters) {
              Object.entries(pref[_this.saveKeyName][_this.projectId].filters).forEach((item) => {
                const lst = new Set(_this.mergedFilterFields.map((x) => x.title));
                if (lst.has(item[0])) {
                  _this.$set(_this.selectedProperties, item[0], item[1]);
                }
              });
              if (Object.keys(_this.selectedProperties).length > 0) {
                _this.selectedPropertiesLogic = pref[_this.saveKeyName][_this.projectId]?.filtersLogic || {};
              }
            } else {
              // console.log('_this.defaultFilters 1',_this.defaultFilters)
              Object.entries(_this.defaultFilters).forEach((item) => {
                const lst = new Set(_this.mergedFilterFields.map((x) => x.title));
                if (lst.has(item[0])) {
                  _this.$set(_this.selectedProperties, item[0], item[1]);
                }
              });
            }
          } else {
            // console.log('_this.defaultFilters 2 ',_this.defaultFilters)
            Object.entries(_this.defaultFilters).forEach((item) => {
              const lst = new Set(_this.mergedFilterFields.map((x) => x.title));
              if (lst.has(item[0])) {
                _this.$set(_this.selectedProperties, item[0], item[1]);
              }
            });
            // console.log('selectedProperties ',_this.selectedProperties)
          }
        }
      });
    });
  },
  watch: {
    commonFilterFields() {
      this.$nextTick(() => {
        // console.log(val)
        this.setMaxTabItemHeight();
      });
    },
  },
  methods: {
    onLoadUserFilterSet(filter) {
      // console.log(filter, type);
      //FIXME: Check if exists

      this.selectedProperties = Object.assign({}, {});
      Object.entries(filter.filters.effectiveSelectedProperties).forEach((prp) => {
        if (this.mergedFilterFields.findIndex((item) => item.title == prp[0]) >= 0) {
          this.$set(this.selectedProperties, prp[0], prp[1]);
        }
      });
      this.$nextTick(() => {
        this.selectedPropertiesLogic = Object.assign({}, {});
        Object.entries(filter.filters.selectedPropertiesLogic).forEach((prp) => {
          if (this.mergedFilterFields.findIndex((item) => item.title == prp[0]) >= 0) {
            this.$set(this.selectedPropertiesLogic, prp[0], prp[1]);
          }
        });
        this.$root.notify.show({ message: `Filter-set ${filter.name} loaded successfully` });
      });
    },

    onFilterInput(prp, val, isReversed) {
      // console.log(val,isReversed)
      this.$set(this.selectedProperties, prp, val);
      this.$set(this.selectedPropertiesLogic, prp, isReversed);
    },
    setMaxTabItemHeight() {
      let tabItems = [];
      tabItems.push(this.$refs.snvTabItems);
      tabItems.push(this.$refs.svTabItems);
      tabItems.push(this.$refs.commonTabItems);

      //   console.log(tabItems)
      let maxHeight = 0;
      for (let i = 0; i < tabItems.length; i++) {
        const height = tabItems[i].$el.clientHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      }
      this.tabMaxHeight = `${maxHeight}px`;
    },
    isValidProp(val) {
      return val !== undefined && val !== "" && val !== null && !(Array.isArray(val) == true && (val.join("") === "" || val.length == 0));
    },

    mergeFilterFields(selectedDatabases, filterFields) {
      let res = [];
      selectedDatabases.forEach((ds) => {
        (filterFields[ds.datasetType.name] || []).forEach((field) => {
          const idx = res.findIndex((item) => item.title == field.title);
          if (idx < 0) {
            let x = { aggs: field.aggs, datatype: field.datatype, filterType: field.filterType, order: field.order, title: field.title, hint: ds.datasetType.name };
            x["datasets"] = [{ id: ds.id, type: ds.datasetType.name, name: field.name }];
            res.push(x);
          } else {
            res[idx].datasets.push({ id: ds.id, type: ds.datasetType.name, name: field.name });
            res[idx].hint = null;
            if (res[idx].filterType == "CATEGORICAL") {
              let set1 = new Set(res[idx].aggs);
              let set2 = new Set(field.aggs);
              res[idx].aggs = Array.from(new Set([...set1, ...set2]));
            } else if (res[idx].filterType == "RANGE_FROM" || res[idx].filterType == "RANGE_TO" || res[idx].filterType == "RANGE_BETWEEN") {
              res[idx].aggs = { min: Math.min(res[idx].aggs.min, field.aggs.min), max: Math.max(res[idx].aggs.max, field.aggs.max) };
              if (res[idx].datatype == "float" || field.datatype == "float") {
                res[idx].datatype = "float";
              }
            }
          }
        });
      });
      return res;
    },
    setFilterInputType(v) {
      this.selectedGenesType = v;
    },
    reset() {
      this.headers = [];
      this.page = 0;
      this.numberOfPages = 0;
      this.totalItems = 0;
      this.isExplored = false;
      this.options = { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] };
      this.selectedProperties = {};
    },
    clearFilter(fieldId) {
      //FIXME: User delete instead of setting
      // console.log(fieldId)
      if (fieldId == "__user_label") {
        this.selectedVariantLabel = [];
      } else if (fieldId == "__collab_label") {
        this.selectedCollabLabel = [];
      } else {
        this.$set(this.selectedProperties, fieldId, "");
        delete this.selectedProperties[fieldId];
        this.$nextTick(()=>{
          this.$set(this.selectedPropertiesLogic, fieldId, false);
        })
      }
    },
    loadDatasetFields(setDefaultValuesCallback) {
      const _this = this;
      const groups = this.fieldGroups.filter((g) => g.groupType === this.filterGroupName).map((item) => item.id);
      if (groups.length == 0) {
        return;
      }
      this.selectedDatabases.forEach((ds) => {
        const selectedDatabaseId = ds.id;
        const databaseType = ds.datasetType.name;
        getDatasetFieldsByOneOfGroups(selectedDatabaseId, groups, function (rs) {
          _this.datasetFilterFields[databaseType] = rs; //.filter((f) => f.groups.findIndex((g) => g.groupType === "LUCID_ANALYSIS") > -1);
          var res = _this.datasetFilterFields[databaseType].map((item) => {
            var x = item;
            x["aggs"] = null;
            x["filterType"] = null;
            if (x.groups.findIndex((item) => item.groupType == "RANGE_FROM") >= 0) {
              x["filterType"] = "RANGE_FROM";
            } else if (x.groups.findIndex((item) => item.groupType == "RANGE_TO") >= 0) {
              x["filterType"] = "RANGE_TO";
            } else if (x.groups.findIndex((item) => item.groupType == "RANGE_BETWEEN") >= 0) {
              x["filterType"] = "RANGE_BETWEEN";
            } else if (x.title === "OVERLAPPING_GENES") {
              x["filterType"] = "GENE";
            }
            return x;
          });
          var catFields = res.filter((f) => f.groups.map((g) => g.groupType).find((x) => x == "CATEGORICAL" || x == "RANGE_BETWEEN" || x == "RANGE_FROM" || x == "RANGE_TO") !== undefined).map((item) => item.name);
          if (catFields.length > 0) {
            getDistincts(_this.cohortId, selectedDatabaseId, catFields, function (resAgg) {
              // console.log(resAgg)
              let distincts = resAgg.aggregations.distincts;
              let distinctFields = Object.keys(distincts);

              let rangeAggs = resAgg.aggregations.rangeAggs;
              let rangeAggsFields = Object.keys(rangeAggs);

              res.forEach((item) => {
                if (distinctFields.includes(item.name)) {
                  item["aggs"] = distincts[item.name].buckets.map((x) => x.key);
                  item["filterType"] = "CATEGORICAL";
                }
                if (rangeAggsFields.includes(item.name)) {
                  item["aggs"] = rangeAggs[item.name];
                }
              });
              _this.$set(
                _this.filterFields,
                databaseType,
                res.sort((a, b) => a.order - b.order)
              );
              setDefaultValuesCallback(ds.datasetType.name, "1");
            });
          } else {
            _this.$set(
              _this.filterFields,
              databaseType,
              res.sort((a, b) => a.order - b.order)
            );
            setDefaultValuesCallback(ds.datasetType.name, "2");
          }
        });
      });
    },
    getFilters(datasetType) {
      let props = Object.entries(this.selectedProperties)
        .filter((x) => this.isValidProp(x[1]))
        .filter((x) => this.filterFields[datasetType].findIndex((field) => field.title == x[0]) >= 0);
      const filters = {};
      const _this = this;
      const getEqualQuery = function (item) {
        let r = {};
        const fieldTitle = item[0];
        const fieldName = _this.datasetFilterFields[datasetType].find((item) => item.title == fieldTitle).name;
        r[fieldName] = item[1];
        return r;
      };

      filters["exactFilters"] = props
        .filter(
          (f) =>
            f[0] !== "OVERLAPPING_GENES" &&
            this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "RANGE_FROM" || g.groupType === "RANGE_TO" || g.groupType === "RANGE_BETWEEN" || g.groupType === "CATEGORICAL") == -1
        )
        .map(getEqualQuery);
      filters["inFilters"] = props
        .filter(
          (f) =>
            this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).title == "OVERLAPPING_GENES" || this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "CATEGORICAL") >= 0
        )
        .filter((f) => this.selectedPropertiesLogic[f[0]] !== true)
        .map(getEqualQuery);

      filters["notFilters"] = props
        .filter(
          (f) =>
            this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).title == "OVERLAPPING_GENES" || this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "CATEGORICAL") >= 0
        )
        .filter((f) => this.selectedPropertiesLogic[f[0]] === true)
        .map(getEqualQuery);

      filters["rangeFromfilters"] = props
        .filter((f) => this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "RANGE_FROM") >= 0)
        .map((item) => {
          let r = {};
          const fieldTitle = item[0];
          const fieldName = this.datasetFilterFields[datasetType].find((item) => item.title == fieldTitle).name;
          r[fieldName] = { gte: item[1] };
          return r;
        });

      filters["rangeTofilters"] = props
        .filter((f) => this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "RANGE_TO") >= 0)
        .map((item) => {
          let r = {};
          const fieldTitle = item[0];
          const fieldName = this.datasetFilterFields[datasetType].find((item) => item.title == fieldTitle).name;
          r[fieldName] = { lte: item[1] };
          return r;
        });

      filters["rangeBetweenfilters"] = props
        .filter((f) => this.datasetFilterFields[datasetType].find((item) => item.title == f[0]).groups.findIndex((g) => g.groupType === "RANGE_BETWEEN") >= 0)
        .map((item) => {
          let r = {};
          const fieldTitle = item[0];
          const fieldName = this.datasetFilterFields[datasetType].find((item) => item.title == fieldTitle).name;
          r[fieldName] = {};
          if (this.isValidProp(item[1][0])) {
            r[fieldName]["gte"] = item[1][0];
          }
          if (this.isValidProp(item[1][1])) {
            r[fieldName]["lte"] = item[1][1];
          }
          return r;
        });

      return filters;
    },
    onExplore() {
      let filtersPerDs = {};
      let variantLabelsPerDs = {};
      let collabLabelsPerDs = {};
      this.selectedDatabases.forEach((ds) => {
        filtersPerDs[ds.datasetType.name] = this.getFilters(ds.datasetType.name);
        variantLabelsPerDs[ds.datasetType.name] = this.selectedVariantLabel.map((lblTitle) => this.variantLabels.find((x) => x.title == lblTitle).id);
        collabLabelsPerDs[ds.datasetType.name] = this.selectedCollabLabelPerUser;
      });
      if (this.saveFilters) {
        let pref = this.$store.state.UserPreferences;
        if (!pref) {
          pref = {};
        }
        if (!pref[this.saveKeyName]) {
          pref[this.saveKeyName] = {};
        }
        if (!pref[this.saveKeyName][this.projectId]) {
          pref[this.saveKeyName][this.projectId] = {};
        }
        if (Object.keys(this.effectiveSelectedProperties).length > 0) {
          pref[this.saveKeyName][this.projectId].filters = this.effectiveSelectedProperties;
          pref[this.saveKeyName][this.projectId].filtersLabels = this.selectedPropertiesLabels;
          pref[this.saveKeyName][this.projectId].filtersLogic = this.selectedPropertiesLogic;
          pref[this.saveKeyName][this.projectId].filtersPerDs = filtersPerDs;
          // console.log(pref[this.saveKeyName][this.projectId].filtersLogic)
        } else {
          pref[this.saveKeyName][this.projectId].filters = null;
          pref[this.saveKeyName][this.projectId].filtersLabels = null;
          pref[this.saveKeyName][this.projectId].filtersLogic = null;
          pref[this.saveKeyName][this.projectId].filtersPerDs = null;
        }
        this.$store.commit("userPreferences", pref);
      }

      // this.filtersToSave= {
      //   filters:this.effectiveSelectedProperties,
      //   filtersLabels : this.selectedPropertiesLabels,
      //   filtersLogic : this.selectedPropertiesLogic,
      //   filtersPerDs : filtersPerDs
      // }

      this.$emit("onFilterSet", filtersPerDs, variantLabelsPerDs, this.selectedPropertiesLabels, collabLabelsPerDs);
    },
  },
};
</script>
  <style scoped>
.filterBox {
  background: #eafbfd91 !important;
}
.PredefinedFiltersBox {
  width: 500px;
  color: grey;
  border: 1px solid #0392a849 !important;
  background: rgba(255, 255, 255, 0.999);
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(3px) !important;
}
.hintText:hover {
  cursor: help;
}
</style>
  