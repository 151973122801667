<template>
  <div>
    <div class="d-flex flex-row">
      <div class="caption grey--text text--darken-1 font-weight-bold">{{ inputTitle }}</div>
      <v-tooltip v-if="description" bottom max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-icon x-small class="mb-1" v-bind="attrs" v-on="on" color="grey">mdi-information-outline</v-icon>
        </template>
        <span> {{ description }} </span>
      </v-tooltip>
      <Help section="VARIANT" class="ml-auto" :lookupKey="title"></Help>
    </div>
    <v-text-field v-if="type == null" dense solo class="pt-0 Filter" v-model="val1" hide-details></v-text-field>
    <v-combobox
      v-if="type == 'CATEGORICAL'"
      :return-object="false"
      validate-on-blur
      :rules="[validationRules.comboboxRule]"
      deletable-chips
      small-chips
      multiple
      hide-details
      dense
      solo
      :menu-props="{ offsetY: true }"
      class="pt-0 Filter"
      clearable
      :items="sortedItems"
      v-model="val1"
    >
      <template v-if="reversible" v-slot:prepend-item>
        <div class="mx-2 d-flex flex-row body-2 grey--text">
          <div class="mt-1 ml-2">{{ isReversed ? "Does NOT contain any of:" : "Contains one of:" }}</div>
          <div class="ml-auto">
            <v-switch class="mt-0 mb-0" hide-details dense v-model="isReversed" @change="onIsRevesedChanged"></v-switch>
          </div>
        </div>
      </template>
      <template v-if="isReversed" v-slot:prepend-inner>
        <v-icon small>mdi-code-not-equal</v-icon>
      </template>
    </v-combobox>
    <div v-if="type == 'RANGE_BETWEEN'">
      <RangeFilter :type="type" :datatype="datatype" :value="[val1, val2]" :min="stats.min" :max="stats.max" @input="onRangeFilterChanged"></RangeFilter>
    </div>
    <div v-if="type == 'RANGE_FROM'">
      <RangeFilter :type="type" :datatype="datatype" :value="[val1, null]" :min="stats.min" :max="stats.max" @input="onRangeFilterChanged"></RangeFilter>
    </div>
    <div v-if="type == 'RANGE_TO'">
      <RangeFilter :type="type" :datatype="datatype" :value="[null, val1]" :min="stats.min" :max="stats.max" @input="onRangeFilterChanged"></RangeFilter>
    </div>
    <div v-if="type == 'GENE'">
      <v-text-field :label="showInputLabel? 'Comma-Separated List of Genes, ex. POLE,FASLG,...' : '' " dense solo class="my-0 Filter" :value="val1" @input="onGeneEntered" clearable hide-details>
        <template slot="append">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="searchGene = true">mdi-magnify</v-icon>
            </template>
            <span>Search for Genes</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="showImportGeneList = true">mdi-tray-arrow-up</v-icon>
            </template>
            <span>Import Gene List</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </div>

    <SearchGene v-if="searchGene" @onClose="searchGene = false" @onGeneSelected="onGeneSelected"></SearchGene>
    <ImportGeneList v-if="showImportGeneList" @onClose="showImportGeneList = false" @onApply="onImportGeneList" :genes="selectedGenes"></ImportGeneList>
  </div>
</template>
<script>
import SearchGene from "@/components/defaultAnalysis/searchGene/SearchGene.vue";
import ImportGeneList from "@/components/defaultAnalysis/ImportGeneList.vue";
import RangeFilter from "@/components/defaultAnalysis/filters/RangeFilter.vue";
import Help from "@/components/Common/Help.vue";
export default {
  name: "FilterInput",
  components: { SearchGene, ImportGeneList, Help, RangeFilter },
  data: function () {
    return {
      isReversed: false,
      val1: null,
      val2: null,
      showImportGeneList: false,
      searchGene: false,
      selectedGenes: [],
      validationRules: {
        comboboxRule: (v) => {
          // console.log(v)
          const res = !!v && v.every((item) => this.items.indexOf(item) >= 0);
          // const res = !v && this.items.indexOf(String(v)) >=0
          return res || "Invalid Value!";
        },
      },
    };
  },
  watch: {
    val1() {
      let r = null;
      r = this.type == "RANGE_BETWEEN" ? [this.val1, this.val2] : this.val1;
      this.$emit("input", r, this.isReversed);
    },
    val2() {
      let r = null;
      r = this.type == "RANGE_BETWEEN" ? [this.val1, this.val2] : this.val1;
      this.$emit("input", r, this.isReversed);
    },
    value() {
      this.setInternalValues()
    },
    filterLogic(){
      this.isReversed = this.filterLogic;
    }
  },
  created() {
    this.setInternalValues()
    this.isReversed = this.filterLogic;
  },
  computed: {
    sortedItems() {
      // console.log(this.items)
      let x = this.items;
      return x.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    },
    inputTitle() {
      let t = "";
      switch (this.type) {
        case null:
        case "CATEGORICAL":
        case "GENE":
          t = this.title;
          break;
        case "RANGE_FROM":
          t = this.title + " ≥";
          break;
        case "RANGE_TO":
          t = this.title + " ≤";
          break;
        case "RANGE_BETWEEN":
          t = "≤ " + this.title + " ≤";
          break;

        default:
          t = "??";
      }
      return t;
    },
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    value: null,
    items: {
      type: Array,
      default: null,
    },
    stats: {
      type: Object,
      default: null,
    },
    datatype: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    reversible: {
      type: Boolean,
      default: true,
    },
    filterLogic: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    showInputLabel: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    setInternalValues(){
      if (this.value) {
        switch(this.type){
          case "RANGE_BETWEEN":
            this.val1 = this.value[0];
            this.val2 = this.value[1];
            break;
          case "RANGE_FROM":
            if(Array.isArray(this.value)){
              this.val1 = this.value[0];
            } else {
              this.val1 = this.value;
            }
            break;
          case "RANGE_TO":
            if(Array.isArray(this.value)){
              this.val1 = this.value[1];
            } else {
              this.val1 = this.value;
            }
            break;
          default:
            this.val1 = this.value;
        }
      } else {
        this.val1 = null;
        this.val2 = null;
      }
      // console.log('setInternalValues',this.type, this.value, this.val1, this.val2)
    },
    onRangeFilterChanged(val, type) {
      if (type == "RANGE_BETWEEN") {
        this.val1 = val[0];
        this.val2 = val[1];
      } else if (type == "RANGE_FROM") {
        this.val1 = val[0];
      } else if (type == "RANGE_TO") {
        this.val1 = val[1];
      }
    },
    onIsRevesedChanged() {
      let r = null;
      r = this.type == "RANGE_BETWEEN" ? [this.val1, this.val2] : this.val1;
      this.$emit("input", r, this.isReversed);
    },
    onGeneEntered(genesStr) {
      if (!genesStr) {
        genesStr = "";
      }
      const genes = genesStr.replaceAll(" ", "").split(",");
      this.selectedGenes = genes;
      this.$emit("inputType", "Manual List");
      this.val1 = genes.map((i) => i.toUpperCase());
    },
    onImportGeneList(genes) {
      this.selectedGenes = genes;
      this.$emit("inputType", "Manual List");
      this.val1 = genes.map((i) => i.toUpperCase());
    },
    onGeneSelected(genes, type) {
      this.$emit("inputType", type);
      this.searchGene = false;
      this.val1 = genes.map((i) => i.toUpperCase());
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .Filter.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07) !important;
}
</style>