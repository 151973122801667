export function gradientTripple(t,start,middle,end) {  
  return t>=0.5 ? linear(middle,end,(t-.5)*2) : linear(start,middle,t*2);
}

export function gradient(t,start,end) {  
  return linear(start,end,t);
}


function linear(s,e,x) {
  let r = byteLinear(s[1]+s[2], e[1]+e[2], x);
  let g = byteLinear(s[3]+s[4], e[3]+e[4], x);
  let b = byteLinear(s[5]+s[6], e[5]+e[6], x);
  return "#" + r + g + b;
}

// a,b are hex values from 00 to FF; x is real number in range 0..1
function byteLinear(a,b,x) {
  let y = (('0x'+a)*(1-x) + ('0x'+b)*x)|0;
  return y.toString(16).padStart(2,'0') // hex output
}


export const staticColors= ["#b3d4ff","#d0d0d0","#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#00bfa0"]
