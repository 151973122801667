 <template>
  <v-expand-transition >
    <div class="frame mx-4 pa-4 mt-n1 flex flex-column">
      <div class="text-h6 mb-4"> {{ this.matchedVariants.length }} Matched Variants in Lucid Grand Truth Databases <Help section="UI" lookupKey="VARIANT_MATCHER"> </Help></div>
      <div class="d-flex flex-row">
        <v-btn small text color="primary" :disabled="currentIndex == 0" @click="onPrev"><v-icon>mdi-chevron-left</v-icon>Previous</v-btn>
        <span class="mx-auto caption"> Score: {{ matchedVariants[currentIndex]?.score || null }}</span>
        <v-btn small text color="primary"  :disabled="currentIndex == matchedVariants.length - 1" @click="onNext" >Next<v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
      <img v-if="cubanImageData"  width="99%"  :src="cubanImageData" />
    </div>
  </v-expand-transition>
</template>
 <script>
import Help from "@/components/Common/Help.vue";
import { getData } from "@/api/user/dataset";
import { getVariantMatcherCubanImage } from "@/api/user/dataset.js";

export default {
  name: "VariantMatcher",
  components: { Help },
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    datasetId: {
      type: Number,
      required: true,
    },
    variantId: {
      type: String,
      required: true,
    },
    idField: {
        type: String, 
        required: true,
    }
  },
  data: () => ({
    help: null,
    cubanImageData: null,
    currentIndex: -1,
    matchedVariants: [],
  }),
  computed:{
    // currentVariant(){

    // }
  },
  watch: {
    currentIndex() {
      // console.log(newVal,oldVal)
      this.loadImage();
    },
    variantId(newVal,oldVal){
      console.log('variantId Watch',newVal,oldVal)
    }
  },
  created() {
    this.help = this.$root.help["UI__VARIANT_MATCHER"];
    this.getMatchedVaraints();
    // this.loadImage();
  },
  methods: {
    onNext(){
      if(this.currentIndex < this.matchedVariants.length - 1 ){
        this.currentIndex ++
      }
    },
    onPrev(){
      if(this.currentIndex > 0 ){
        this.currentIndex --
      }
    },

    getMatchedVaraints() {
      const _this = this;
      let idFilter = {};
      idFilter[this.idField] = this.variantId;
      const exactFilters = [idFilter];
      getData(this.cohortId, this.datasetId, exactFilters, [], [], [], [], "one_of_all", 1, 0, [], [], [], ["VARIANTMATCHER"], [], function (res) {
        _this.matchedVariants = res.result[0].VARIANTMATCHER.sort((a,b) => a.score - b.score);
        // console.log('Count:',_this.matchedVariants.length)
        if(_this.matchedVariants.length > 0){
            _this.currentIndex = 0
            // _this.loadImage()
        }
      });
    },
    loadImage() {
      const _this = this
      getVariantMatcherCubanImage(
        this.matchedVariants[this.currentIndex].id,
        (res) => {
          // console.log('newData', _this.matchedVariants[this.currentIndex])
          _this.cubanImageData = URL.createObjectURL(res);
        },
        () => {
          _this.cubanImageData = null;
        }
      );
    },
  },
};
</script>


<style scoped>
.frame {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #d9d9d9;
  background: #eeeeee;
  border-style: solid;
}
</style>