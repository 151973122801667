<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-if="val == id" :style="`width: ${width}px !important`" class="d-flex flex-row">
      <v-text-field validate-on-blur required :rules="nameRules" class="updateFilterSetName body-2 font-weight-bold" hide-details flat dense outlined v-model="newTitle"> </v-text-field>
      <v-btn icon x-small @click="val = id">
        <v-icon class="ml-1" color="green lighten-1" @click="onUpdate">mdi-check</v-icon>
      </v-btn>
      <v-btn icon x-small @click="val = null">
        <v-icon class="ml-1" color="red lighten-1">mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-else class="d-flex flex-row">
      <span ref="titleLabel" class="font-weight-bold">{{ title }}</span>
      <v-btn icon x-small @click="onEditClick">
        <v-icon small class="ml-1" color="grey lighten-1">mdi-pencil-outline</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>
<script>
export default {
  name: "EditableLabel",
  props: {
    id: null,
    value: null,
    title: null,
    maxLength:{
        type: Number,
        default:200
    }
  },
  data: () => ({
    valid:null,
    width: 100,
    val: null,
    newTitle: null,
    nameRules: null

  }),
  created() {
    this.nameRules = [(v) => !!v || "Value is required", (v) => (!!v && v.length <= this.maxLength) || `Must be less than ${this.maxLength} characters`],
    this.val = this.value;
  },
  watch: {
    value() {
      this.val = this.value;
    },
    val() {
      if (this.val != this.value) {
        this.$emit("input", this.val);
      }
    },
  },
  methods: {
    onEditClick() {
      this.newTitle = this.title;
      this.width = Math.min(Math.max(this.$refs.titleLabel.clientWidth * 1.4 + 60, 180), 500);
      console.log(this.$refs.titleLabel.clientWidth);
      this.val = this.id;
    },
    onUpdate() {
        if(this.$refs.form.validate()){
            this.$emit("onUpdate", this.id, this.newTitle);
        }
    },
  },
};
</script>