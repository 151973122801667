<template>
    <div>
        <!-- <v-shape :config="connectorConfig"/> -->
        <v-line  :config="lineConfig"/>  

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
Vue.use(VueKonva);
import { toScatedX } from '@/utils/tad.js'

export default {
    name:'Connector',
    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        // from:{
        //     type: Number,
        //     default:0
        // },
        // to:{
        //     type: Number,
        //     default:0
        // },
        // type:{
        //     type:String,
        //     default: 'OLD'
        // },
        spec:{
            from:null,
            to:null,
            colorStr:null,
            color:null
        },
        fromBaseLineY:{
            type: Number,
            required: true
        },
        toBaseLineY:{
            type: Number,
            required: true
        },
        lineHeight:{
            type: Number,
            required: true
        }
    },
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
        sceneFunc(context,shape){
            context.beginPath();
            context.moveTo(this.from, this.fromBaseLineY);
            const curve = Math.abs( Math.round(this.to - this.from))
            context.quadraticCurveTo((this.from + (this.to - this.from)/2) , this.toBaseLineY - curve, this.to , this.toBaseLineY + this.spec.line*30);
            context.fillStrokeShape(shape);            
        }
    },
    computed:{
        from(){
            return this.toX(this.spec.from)
        },
        to(){
            return this.toX(this.spec.to)
        },
        connectorConfig(){
            return {
                    sceneFunc: this.sceneFunc,
                    // fill: '#00D2FF',
                    stroke: this.spec.colorStr,
                    strokeWidth: 1
                } 
        },
        lineConfig(){
            return {
                x: 0,
                y: 0,
                points: [this.from, this.fromBaseLineY, this.to, this.toBaseLineY+ this.spec.line * this.lineHeight],
                stroke: '#888888',
                strokeWidth: 1,
            } 
        }
    }
    
}
</script>