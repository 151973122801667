<template>
    <div >
        <v-rect :config="rectConfig"  ></v-rect>
        <!-- <v-rect :config="rectConfig"  ></v-rect> -->
        <!-- <v-text v-if="this.info.sample && this." :config="textConfig" ></v-text> -->
        <Tooltip  :region="tooltipRegion" :text="tooltipText" @mouseenter="onMouseEnter" @mouseout="onMouseLeave" ></Tooltip> 

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'

Vue.use(VueKonva);
import { toScatedX } from '@/utils/tad.js'
export default {
    name:'RarePathogenicSV',
    components:{Tooltip},
    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        info:{
            type:Object,
            default: () => {}
        },
        baseLineY:{
            type: Number,
            default:450
        },
        enableHightlighSameSamples:{
         type:Boolean,
         default: false
        }
        // color:{
        //     type:[],
        //     required: true
        // }
        
    },
    data:()=>({
       
        selected:false,
        hover:false,
        HEIGHT: 14,
        hasNotCommonSample:false,
        
    }),
    mounted(){
        this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onOtherVariantMouseEnter(prm)})
        this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onOtherVariantMouseLeave(prm)})
    },

    methods:{
        onOtherVariantMouseEnter(prm){
            if(this.enableHightlighSameSamples == true && prm.sample !== null  &&  this.info.sample !== prm.sample){
                this.hasNotCommonSample = true
            }
        },
        onOtherVariantMouseLeave(){
            this.hasNotCommonSample = false            
        },
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
        onMouseEnter(){
            this.$eventBus.$emit('onVariantMouseEnter', { start:this.info.start, end:this.info.end, widthInPx:Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth), sample:this.info?.sample || null, id:this.info?.id || null   })
        },
        onMouseLeave(){
            this.$eventBus.$emit('onVariantMouseLeave', { start:this.info.start, end:this.info.end, widthInPx:Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth), sample:this.info?.sample||null, id:this.info?.id || null})
        },
    },
    computed:{
        minWidth(){
            return 2
        },
        tooltipText(){
            let text = ''
            const infoKeys=['type','sample','gt','chr','size','disease status','clinvar','start','end','id']
            infoKeys.forEach(k => {
                const title = k.substr(0,1).toUpperCase()+k.substr(1)

                if(this.info[k] !== undefined){
                    text += ` ${title}: ${this.info[k]} \n` 
                }
            })
            return text
            // if (this.info.sample){
            //     return `${this.info.type} in ${this.info.sample} \n   Size: ${this.info.size} \n   Genotype: ${this.info.gt} `
            // } else {
            //     return `${this.info.type} \n   Size: ${this.info.size} \n   Genotype: ${this.info.gt}`
            // }
        },
        tooltipRegion(){
            return {
                x: this.toX( this.info.start) -3, 
                y: this.baseLineY -2, 
                width: Math.max(this.toX(this.info.end) - this.toX(this.info.start) ,8) , 
                height: this.HEIGHT + 4, 
            }         
        },
        textConfig(){
            return {
                x: this.toX(this.info.start) - 5,
                y: this.baseLineY - 5, 
                width: 50, 
                text: this.info.type, 
                fontSize: 10, 
                // fontStyle: 'bold',
                fill:'navy',
                align:'left',
                // onMouseEnter: this.onMouseEnter,
                // onMouseLeave: this.onMouseLeave,
                // onClick: this.onMouseClick
            } 
        },
        rectConfig(){
            
            return {
                x: this.toX(this.info.start) , 
                y: this.baseLineY +2, 
                // width: 50 , 
                width: Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth)  , 
                height: this.HEIGHT, 
                // stroke: this.hasCommonSample? 'black' : this.info.color , 
                // strokeWidth: 1 , 
                // strokeWidth:  1  , 
                // fill: this.colors[this.info.type],
                opacity: this.hasNotCommonSample ? 0.2 :1,
                fill: this.info.color,
                shadowBlur: this.hover? 3 : 0,
                // onMouseEnter: this.onMouseEnter,
                // onMouseLeave: this.onMouseLeave,
                // onClick: this.onMouseClick
                // cornerRadius: 2,
            }
        },
    }
    
}
</script>