<template>
  <div>
    <v-menu offset-y left min-width="380px" v-model="menu" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" text color="primary" class="ml-auto" v-on="on"> <v-icon small class="mr-1">mdi-share-variant-outline</v-icon>Share Result </v-btn>
      </template>
      <v-card style="width: 650px">
        <v-card-title class="d-flex flex-row pa-3">
          <div class="text-h6 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-share-variant-outline</v-icon>Share the Result</div>
          <v-btn icon class="ml-auto" @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column px-8">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="d-flex flex-row py-1" v-for="(item, index) in shareList" :key="index">
              <v-text-field class="mr-1" validate-on-blur required :rules="nameRules" style="width: 130px" v-model="shareList[index].first_name" outlined hide-details dense placeholder="First Name"></v-text-field>
              <v-text-field class="mr-1" validate-on-blur required :rules="nameRules" style="width: 130px" v-model="shareList[index].last_name" outlined hide-details dense placeholder="Last Name"></v-text-field>
              <v-text-field class="mr-1" validate-on-blur required :rules="emailRules" style="width: 230px" v-model="shareList[index].email" outlined hide-details type="email" dense placeholder="Email"></v-text-field>
              <v-btn v-if="shareList.length > 1" icon color="primary" class="ml-auto" @click="removeItem(index)"><v-icon small>mdi-close</v-icon></v-btn>
            </div>
            <v-textarea outlined hide-details v-model="shareComment" height="80" placeholder="Your Comment"></v-textarea>
            <div class="d-flex flex-row mt-2 mb-4">
              
              <v-menu offset-y  v-model="coworkersMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="shareList.length == 5" text small color="primary" ><v-icon small>mdi-plus</v-icon> Add from {{ organizationName }}</v-btn>
                </template>
                <v-list>
                  <v-list-item dense v-for="item in collaborators" :key="item.email" class="d-flex flex-row" style="min-height: 30px!important;max-height: 30px!important;" @click="onAddFromCollaborators(item)">
                    <div class="mr-3 body-2">
                      {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div class="ml-auto caption">
                      {{ item.email }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              
              <v-btn :disabled="shareList.length == 5" text small color="primary"  @click="addItem"><v-icon small>mdi-plus</v-icon> Add other people</v-btn>
              
              <v-btn color="primary" class="ml-auto" @click="onShare"><v-icon small>mdi-share-variant-outline</v-icon>Share</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { shareResult } from "@/api/user/collab.js";
import { getCoworkers } from "@/api/users"

export default {
  name: "ShareResult",
  data: () => ({
    coworkersMenu: false,
    menu: false,
    valid: false,
    shareComment: null,
    shareList: [],
    nameRules: [(v) => !!v || "Name is required", (v) => (!!v && v.length <= 50) || "Must be less than 10 characters"],
    emailRules: [(v) => !!v || "E-mail is required", (v) => (!!v && /.+@.+/.test(v)) || "E-mail must be valid"],
    collaborators:[]
  }),
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    sampleId: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.reset();
    const _this = this
    getCoworkers( (res)=> { 
      _this.collaborators = res}
    )
  },
  computed:{
    organizationName() {
      return this.$store.state.Organization.name;
    },
  },
  methods: {

    onAddFromCollaborators(item){
      console.log(item)
      if(this.shareList[this.shareList.length-1].email){
        this.addItem()
      } 
      this.shareList[this.shareList.length-1].email = item.email
      this.shareList[this.shareList.length-1].first_name = item.first_name
      this.shareList[this.shareList.length-1].last_name = item.last_name
        
      this.coworkersMenu= false
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.shareList = [{ first_name: null, last_name: null, email: null }];
      this.shareComment = null;
    },
    close() {
      this.reset();
      this.menu = false;
    },
    removeItem(index) {
      this.shareList.splice(index, 1);
    },
    addItem() {
      this.shareList.push({ first_name: null, last_name: null, email: null });
    },
    onShare() {
      if (this.$refs.form.validate()) {
        const payload = {
          share_with: this.shareList,
          comment: this.shareComment,
          sv_filters: this.filters.sv_filters,
          snv_filters: this.filters.snv_filters,
        };
        shareResult(
          this.cohortId,
          this.sampleId,
          payload,
          () => {
            this.close();
          },
          `Selected variants will be shared with ${this.shareList.map((item) => item.email).join(", ")}`
        );
      }
    },
  },
};
</script>