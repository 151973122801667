<template>
  <div>
    <v-rect :config="exonConfig"></v-rect>
    <Tooltip  :region="tooltipRegion" :text="tooltipText" ></Tooltip> 

  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import { toScatedX } from "@/utils/tad.js";
// import { getTextSizeInPixel } from "@/utils/textUtils.js";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'

Vue.use(VueKonva);

export default {
  name: "Exon",
  components: {Tooltip},
  data: () => ({
    // height: 15,
    // exonHeight: 6,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
  }),
  props: {
    exonHeight:{
        type:Number,
        required: true,
    },
    tadInfo: {
      type: Object,
      required: true,
    },
    exon:{
        type: Object,
       required: true,
    },
    spec: {
      blockCount: null,
      blockSizes: [],
      blockStarts: [],
      chr: null,
      direction: null,
      end: null,
      itemRgb: null,
      name: null,
      score: null,
      start: null,
      thickEnd: null,
      thickStart: null,
      isoform_id: null
    },
    baseLineY: {
      type: Number,
      default: 500,
    }
  },
  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
  },
  created(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },
  computed: {
    tooltipText(){
        return `Exon Start: ${this.exon.start}               Exon End: ${this.exon.end}\nIsoform Start: ${this.spec.start}     Isoform End:   ${this.spec.end} Name: ${this.spec.name}              Isoform ID: ${this.spec.isoform_id}`
    },
    tooltipRegion(){
            return {
                 x: this.toX( this.exon.start) - 2, 
                y: this.baseLineY - this.exonHeight / 2 - 2, 
                width: Math.max(this.toX(this.exon.end) - this.toX(this.exon.start) + 4 ,8) , 
                height: this.exonHeight + 4, 
            }         
        },
    exonConfig() {
        let color = "blue"
        if(
            // (this.sv.start  &&  this.sv.end >= this.exon.start && this.sv.start <= this.exon.end)
            (this.exon.isHitBySv)
            ||
            (this.showSecondaryHighlight &&  this.secondaryHightlightInfo.end >= this.exon.start && this.secondaryHightlightInfo.start <= this.exon.end)
        ){
          color = "red"
        }
        return {
          x: this.toX(this.exon.start),
          y: this.baseLineY - this.exonHeight / 2,
          width: this.toX(this.exon.end) - this.toX(this.exon.start),
          height: this.exonHeight,
          fill: color,
          strokeWidth: 1,
          stroke: color,
        };
    }
  },
};
</script>