<template>
  <div class="boxFrame pa-10 d-flex flex-column" >
    <LucidAllAnalysisStatistics :sampleId="sampleId" :cohortId="cohortId"></LucidAllAnalysisStatistics>

    <div class="d-flex flex-row mt-6 mx-auto">
      <router-link :to="{ name: 'DefaultAnalysis', params: { id: sampleId, cohortid: cohortId } }"  class="text-decoration-none">
        <div class="linkFrame d-flex flex-column" style="width: 230px;height: 230px;">
          <v-img class="mx-auto" src="@/assets/img/variant-explorer.svg" width="135" :contain="true"></v-img>
          <div class="mx-auto font-weight-medium" style="text-decoration-line: none !important;">VARIANT EXPLORER</div>
        </div>
      </router-link>

      
      <router-link :to="{ name: 'ManualCohortAnalysis', params: { id: sampleId, cohortid: cohortId } }"  class="text-decoration-none">
        <div class="linkFrame d-flex flex-column" style="width: 230px;height: 230px;">
          <v-img class="mx-auto" src="@/assets/img/cohort-analysis.svg" width="175" :contain="true"></v-img>
          <div class="mx-auto font-weight-medium" style="text-decoration-line: none !important;">COHORT ANALYSIS</div>
        </div>
      </router-link>
      
      
      <router-link :to="{ name: 'LucidAllAnalysisSamples', params: { id: sampleId, cohortid: cohortId } }" class="text-decoration-none">
        <div class="linkFrame d-flex flex-column" style="width: 230px;height: 230px;">
          <v-img class="mx-auto" src="@/assets/img/individual-analysis.svg" width="170" :contain="true"></v-img>
          <div class="mx-auto font-weight-medium" style="opacity: 1 !important;">INDIVIDUAL ANALYSIS</div>
        </div>
      </router-link>
      <!-- <v-btn :to="{ name: 'DefaultAnalysis', params: { id: sampleId, cohortid: cohortId } }" color="primary" class="ml-auto mr-4" width="250"><v-icon class="mr-1">mdi-database-search-outline</v-icon>Variant Explorer</v-btn> -->
      <!-- <v-btn :to="{ name: 'ManualCohortAnalysis', params: { id: sampleId, cohortid: cohortId } }" color="primary" class="mr-auto ml-4" width="250"><v-icon class="mr-1">mdi-account-search-outline</v-icon>Cohort Explorer</v-btn> -->
      <!-- <v-btn :to="{ name: 'LucidAllAnalysisSamples', params: { id: sampleId, cohortid: cohortId } }" color="primary" class="ml-10 mr-4" width="250"><v-icon class="mr-1">mdi-account-outline</v-icon>Individual Analysis</v-btn> -->
      <!-- <v-btn :to="{ name: 'LucidAllCohortAnalysis', params: { id: sampleId, cohortid: cohortId } }"  color="primary" class="mr-auto ml-4" width="250"><v-icon class="mr-1" >mdi-account-group-outline</v-icon>Cohort Analysis</v-btn> -->
    </div>
  </div>
</template>
<script>
import LucidAllAnalysisStatistics from "@/components/LucidAllAnalysis/LucidAllAnalysisStatistics.vue";

export default {
  name: "LucidAllAnalysisCohortOverview",
  components: { LucidAllAnalysisStatistics },
  data: () => ({
    expand: null,
    sampleId: null,
    cohortId: null,
  }),
  created() {
    this.sampleId = Number(this.$route.params.id);
    this.cohortId = Number(this.$route.params.cohortid);
  },
};
</script>

<style lang="scss" scoped>
.linkFrame {
    border-radius: 8px;
    border: 1px solid #0393A8;
    background: #ffffff90;
    border-style: solid;
    padding: 16px;
    margin-right: 20px;
    margin-left: 20px;
    opacity: 0.85;
}

.linkFrame:hover{
  border: 1px solid #0393A899;
  background: #0393A810;
  opacity: 1;

}
</style>