<template>
    <div >
        <v-rect :config="rectConfig" @mousemove="showText=true" @mouseleave="showText=false" @mouseout="showText=false"></v-rect>
        <v-text  :config="textConfig" ></v-text>
        <v-text v-if="showText" :config="startTextConfig" ></v-text>
        <v-text v-if="showText" :config="endTextConfig" ></v-text>
        <Tooltip v-if="info.info"  :region="tooltipRegion" :text="tooltipText" ></Tooltip> 

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'
import {staticColors } from '@/utils/color.js'
import { toScatedX } from '@/utils/tad.js'

import {getTextSizeInPixel } from '@/utils/textUtils.js'
Vue.use(VueKonva);

export default {
    name:'DynamicInstant',
    components:{Tooltip},

    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        info:{
            name:null,
            start:null,
            end: null,
            info:null,
            color:null,
        },
        baseLineY:{
            type: Number,
            default:450
        },
        textPosition:{
            type: String,
            default: 'top'
        }
    },
    data:()=>({
        showText:false
    }),
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
    },
    computed:{
        tooltipText(){
            return `${this.info.name} [${this.info.start},${this.info.end}]: ${this.info.info}`
        },
        tooltipRegion(){
            return {
                x: this.toX( this.info.start)  -10, 
                y: this.baseLineY , 
                width: this.toX(this.info.end) - this.toX(this.info.start) + 20, 
                height: 25,
                // strokeWidth:0.5,

                // fill:'red',
                // stroke:'black' 
            }         
        },
        textConfig(){
            return {
                x: this.toX(this.info.start)  -40,
                y: this.baseLineY  + (this.textPosition == 'top' ? 0 : 22), 
                width: this.toX(this.info.end) - this.toX(this.info.start) +80,
                text: this.info.name, 
                fontSize: 9, 
                wrap:'none',
                // fontStyle: 'bold',
                fill:'#555555',
                align:'center',
                // onMouseEnter: this.onMouseEnter,
                // onMouseLeave: this.onMouseLeave,
                // onClick: this.onMouseClick
            } 
        },
        startTextConfig(){
            return {
                x: this.toX(this.info.start) - getTextSizeInPixel(this, 10,this.info.start ) -15,
                y: this.baseLineY+16, 
                width: 50 , 
                text: this.info.start, 
                fontSize: 10, 
                fill:'grey',
                align:'left',
            } 
        },
        endTextConfig(){
            return {
                x: this.toX(this.info.end) +5 ,
                y: this.baseLineY+16, 
                width: 50 , 
                text: this.info.end, 
                fontSize: 10, 
                fill:'grey',
                align:'left',
            } 
        },
        rectConfig(){
            const t= 
             {
                x: this.toX(this.info.start)  , 
                y: this.baseLineY +10, 
                width: Math.max( this.toX(this.info.end) - this.toX(this.info.start) ,3) , 
                height: 10, 
                stroke: 'grey',
                strokeWidth:0.5,
                // strokeWidth: this.selected? 1 : 0 , 
                // fill: 'red',
                fill: staticColors[this.info.color%staticColors.length],
                // shadowBlur: this.hover? 3 : 0,
                cornerRadius: 6,
            }
            // console.log('INS', t, this.color)
            return t

        },

    }
    
}
</script>