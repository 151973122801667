<template>
  <div>
    <v-rect ref="region" :config="tooltipRegion" @mousemove="onMouseOver" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @mouseout="onMouseOut" @click="onMouseClick"></v-rect>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Konva from "konva";
Vue.use(VueKonva);
Vue.use(Konva);

export default {
  name: "Tooltip",
  data: () => ({
    height: 15,
    x: null,
    y: null,
    timer: null,
    enterTimer: null,
    tooltip: null,
    // myText:
    //   " 1 file changed, 0 insertions(+), 0  deletions(-) Enumerating objects: 5, done.Counting objects: 100% (5/5), done.Delta compression using up to 12 threadsCompressing objects: 100% (3/3), done.Writing objects: 100% (3/3), 5.03 MiB | 2.82 MiB/s, done.Total 3 (delta 1), reused 0 (delta 0), pack-reused 0remote: Resolving deltas: 100% (1/1), completed with 1 local object.To github.com:hessamkhoshniat/lucid-ui.git   b67cd27..4046f91  master -> masterEnumerating objects: 1, done.Counting objects: 100% (1/1), done.Writing objects: 100% (1/1), 189 bytes | 189.00 KiB/s, done.Total 1 (delta 0), reused 0 (delta 0), pack-reused 0To github.com:hessamkhoshniat/lucid-ui.git * [new tag]         1.9.3 -> 1.9.3",
  }),
  props: {
    region: null,
    text: {
      type: String,
      require: true,
    },
  },
  mounted() {
    this.$eventBus.$on("onCtrlkeyup", () => {
      this.onCtrlKeyUp();
    });
  },
  methods: {
    onCtrlKeyUp() {
      this.hideTooltip();
    },
    hideTooltip(t) {
      clearTimeout(this.enterTimer);
      this.enterTimer = null
      if (this.tooltip && !this.$root.ctrlKey) {
        this.tooltip.hide();
        delete this.tooltip
        this.tooltip = null
      }
      clearTimeout(this.timer);
      this.timer = null;
      this.$emit("mouseout", t);
    },
    setXY(t) {
      let stage = this.$refs.region.getNode().getLayer().getParent();
      if (t.evt.offsetX + this.tooltipWidth + 20 > stage.attrs.width) {
        this.x = t.evt.offsetX - this.tooltipWidth - 15;
      } else {
        this.x = t.evt.offsetX;
      }
      this.y = t.evt.offsetY;
      if (this.tooltip) {
        if (t.evt.offsetY + this.tooltip.height() > stage.attrs.height) {
          this.y = stage.attrs.height - this.tooltip.height() +10;
          console.log('Final Y:',this.y)
        }
      }
    },
    onMouseOver(t) {
      this.setXY(t);
      if (this.tooltip) {
        this.tooltip.position({ x: this.x + 8, y: this.y + 40 });
      }
      if (!this.timer) {
        this.timer = setTimeout(() => {
          // console.log('mouseOver')
          this.$emit("mouseover", t);
        }, 200);
      }
    },
    onMouseClick(t) {
      this.$emit("mouseclick", t);
    },
    onMouseEnter(t) {
      if(this.enterTimer == null){
        this.enterTimer = setTimeout(() => {
        this.setXY(t);
        if (!this.tooltip) {
          this.tooltip = new Konva.Label({
            x: this.x,
            y: this.y + 15,
            opacity: 0.9,
            draggable: true
          });
          this.tooltip.add(
            new Konva.Tag({
              fill: "#FFFFFFf0",
              pointerDirection: "left",
              pointerWidth: 0,
              pointerHeight: 0,
              lineJoin: "round",
              cornerRadius: 8,
              strokeWidth: 0.5,
              stroke: "#0393A8",
            })
          );

          this.tooltip.add(
            new Konva.Text({
              text: this.text,
              fontSize: 14,
              fill: "black",
              align: "left",
              padding: 9,
              lineHeight: 1.3,
              fontFamily: "Calibri",
              width: this.tooltipWidth,
            })
          );
          this.$refs.region.getNode().getLayer().add(this.tooltip);
        } else {
          this.tooltip.show();
        }

        this.$emit("mouseenter", t);
      }, 150);        
      }

    },
    onMouseLeave(t) {
      this.hideTooltip(t);
      // console.log('mouseleave')
    },
    onMouseOut(t) {
      this.hideTooltip(t);
    },
    getTextWidth(text) {
      const font = "normal 12px Calibri";
      let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
      let context = canvas.getContext("2d");
      context.font = font;
      let metrics = context.measureText(text);
      return metrics.width * 1.05 + 30;
    },
  },
  computed: {
    tooltipWidth() {
      // this.text.split('\n').map(item=>)
      return Math.min(this.getTextWidth(this.text), 350);
    },
    tooltipRegion() {
      return {
        x: this.region.x,
        y: this.region.y,
        width: this.region.width,
        height: this.region.height,
        strokeWidth: 0,
        // fill: '#ff000050',
      };
    },
  },
};
</script>
