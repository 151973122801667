import { utils, writeFileXLSX } from 'xlsx';

export default {
    methods:{
        excelizeResult(res){
            return res.map( item => {
              let r = {}
              Object.keys(item).forEach(x => {
                if(x == '__usr'){
                  r['Label'] = item.__usr?.label?.title || null
                  r['Comment'] = item.__usr?.note || null
                } else if( x == '__organization' || x == '__row_number'){
                  //DO nothing
                } else if (Array.isArray(item[x])){
                  r[x]=item[x].toString()
                } else {
                  r[x]=item[x]
                }
              })
              return r
              
            })
          },
          exportToExcel(fileName, sheets){
            const wb = utils.book_new();
            sheets.forEach(sh => {
              const r = utils.json_to_sheet(this.excelizeResult(sh.data));
              utils.book_append_sheet(wb, r, sh.name);
            })
            writeFileXLSX(wb, fileName + ".xlsx");
          },
    }
}