 <template>
  <v-menu right v-model="menu" open-on-click offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-account-group-outline</v-icon>
    </template>

    <v-card width="600px" max-height="">
      <v-card-title class="grey lighten-2 d-flex flex-row pt-1 pb-1">
        {{ organizationName }}
        <v-btn icon class="ml-auto" color="grey" @click="menu=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text >
        <div v-for="(user, idx) in organization" :key="'u-' + idx">
          <div class="d-flex flex-row mt-3">
            <div class="black--text">  {{ user.first_name + " " + user.last_name }}</div>
            <div v-if="user?.label?.id" class="ml-auto font-weight-medium text-uppercase" :style="'color:' + variantLabelColors[user.label.id]">{{ user.label.title }}</div>
            <div v-else class="ml-auto grey--text" >[ NO LABEL]</div>
          </div>
          <div class="body-2 mt-1 mb-2 ml-4 mr-4">{{ user?.note || null }}</div>
          <v-divider  v-if="idx < organization.length -1"></v-divider>
        </div>

      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
    name:'LucidAllAnalysisOrganization',
    props:{
        organization:{
            type: Array,
            required: true
        },
        variantLabelColors:{
            type: Object,
            required:true
        }
    },
    data:()=>({
        menu:false
    }),
    computed:{
        organizationName() {
      // console.log('store',this.$store.state)
      return this.$store.state.Organization.name;
    },
    }
}
</script>
<style scoped lang="scss">
::v-deep .v-text-field--outlined .fieldset {
    border: 0px solid red !important;
}
</style>