<template>

<v-menu right v-model="menu" open-on-click offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="note" v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-eye-outline</v-icon>
      <v-icon v-else v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-outline</v-icon>
    </template>

    <v-card width="600px" max-height="">
      <v-card-title class="grey lighten-2 d-flex flex-row pt-1 pb-1">
        Comment
        <v-btn icon class="ml-auto" color="grey" @click="menu=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column" >
        
        <v-textarea class="mt-4" outlined name="input-7-1"  v-model="comment" hide-details=""></v-textarea>
        <v-btn class="ml-auto mt-2" color="primary"  :disabled="!comment" @click="onSave()" >Save</v-btn>
        
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { updateVariantAnnotation } from "@/api/user/dataset";

export default {
  name: "LucidAllAnalysisNote",
  props: {
    note: {
      type: String,
      default: null,
    },
    variantId: {
      type: String,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    databaseId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    comment: null,
    menu: false,
  }),
  created() {
    this.comment = this.note;
  },
  methods: {
    onCancel() {
      this.menu = false;
    },
    onSave() {
      const payload = {
        note: this.comment,
      };
      const _this = this;
      updateVariantAnnotation(
        this.cohortId,
        this.databaseId,
        this.variantId,
        payload,
        () => { 
            _this.menu = false
        },
        "Comment saved successfully "
      );
    },
  },
};
</script>