
import store from '@/store'

export function save_user_pref(section, value){
    let prf = store.state.UserPreferences;
    if (!prf) {
      prf = {};
    }
    prf[section] = value
    store.commit("userPreferences", prf);
}

export function get_user_pref(section){
    const pref = store.state.UserPreferences;
    if (pref && pref[section]){
      return pref[section]
    } else {
        return null
    }
}
