<template>
<v-card flat rounded="0" >
  <v-card-title class="primary--text grey lighten-3" >
    {{diseaseName}}
    </v-card-title>
  <v-card-text>
  <div class="d-flex flex-column my-3">
    <v-progress-linear v-if="loading" class="mt-n3 mb-1" color="primary  lighten-4" indeterminate height="6"></v-progress-linear>
    <v-row>
      <v-col cols="3">
        <div class="text-subtitle-2 grey--text text--darken-2 font-weight-bold">ASSOCIATED GENES:</div>
        <div class="text-caption grey--text">Click on the gene to choose it</div>
      </v-col>
      <v-col cols="9">

        <div class="d-flex flex-row">
          <div class="d-flex flex-row flex-wrap">
            <v-chip small v-for="gene in diseaseInfo.genes" :key="gene.id" color="primary" rounded outlined >{{gene.name}}</v-chip>
          </div>
          <v-btn small :disabled="diseaseInfo.genes.length == 0" class="ml-auto" color="secondary" outlined @click="onGeneSelected()">
            Choose Genes
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-subtitle-2 grey--text text--darken-2 font-weight-bold">ASSOCIATIONS:</v-col>
      <v-col cols="9">
        <div v-for="cat in Object.keys( diseaseInfo.categories)" :key="cat">
          <span class="text-subtitle-2 grey--text text--darken-3 font-weight-bold">{{cat}}: </span>
          <v-chip small class="mt-1 mr-1" color="grey lighten-3" v-for="term in diseaseInfo.categories[cat]" :key="term.name">{{term.name}} </v-chip>
        </div>
      </v-col>
    </v-row>
  </div>
  </v-card-text>
</v-card>



</template>
<script>
import {getAnnotation} from '@/api/gene/hpo.js'
export default {
  name: 'DiseaseInfo',
  props:{
    diseaseId:{
      type: String,
      default: ''
    },
    diseaseName:{
      type: String,
      default: ''
    }
  },
  data:()=>({
    diseaseInfo:{
      genes:[],
      categories:{}
    },
    loading: true
  }),
  methods:{
    onGeneSelected(){
      this.$emit('onGeneSelected', this.diseaseInfo.genes.map(item => item.name), 'Disease: ' + this.diseaseName)
    }
  },
  created(){
    const _this = this
    getAnnotation(this.diseaseId, function(res){
      _this.loading = false
      _this.diseaseInfo = res
    })

  }
}
</script>