<template>
    <div >
        <v-rect v-if="type=='square'" :config="rectConfig"  ></v-rect>
        <v-rect v-if="type=='hatched'" :config="hatchedBorderConfig"  ></v-rect>
        <v-rect v-if="type=='hatched'" :config="hatchedRectConfig"  ></v-rect>
        <v-line v-if="type=='line'" :config="lineConfig"  ></v-line>
        <v-text :config="textConfig" ></v-text>
    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";

Vue.use(VueKonva);
export default {
    name:'LegendItem',
    props:{
        fillColor:{
            type: String,
        },
        borderColor:{
            type: String,
        },
        type:{
            type: String,
            required: true
        },
        borderStyle:{
            type: String,
        },
        text:{
            type: String,
            required: true
        },
        x:{
            type: Number,
            required: true
        },
        y:{
            type: Number,
            required: true
        }
    },
    methods:{
        
    },
    computed:{
        textConfig(){
            return {
                x: this.x + (this.type == 'line'? 35 : 13 ),
                y: this.y , 
                text: this.text, 
                fontSize: 12, 
                // fontStyle: 'bold',
                fill:'#666666',
                // align:'left',
            } 
        },
        lineConfig(){
            // const end = this.toX(this.stepCount * this.stepSize) 
            return {
                x: this.x,
                y: this.y + 4,
                points: [0, 0, 30, 0],
                stroke: this.borderColor,
                strokeWidth: 2,
                dash: (this.borderStyle == 'dashed')? [3,2] : null
            } 
        },
        rectConfig(){
            return {
                x: this.x , 
                y: this.y, 
                width: 10  , 
                height: 10, 
                // stroke: 'black',
                // strokeWidth: this.selected? 1 : 0 , 
                // strokeWidth:  1  , 
                // fill: this.colors[this.info.type],
                fill: this.fillColor,
                // shadowBlur: this.hover? 3 : 0,
                cornerRadius: 2,
            }


        },
        hatchedBorderConfig(){
            return {
                x: this.x , 
                y: this.y, 
                width: 10  , 
                height: 10, 
                stroke: 'black',
                // strokeWidth: this.selected? 1 : 0 , 
                strokeWidth:  0.5  , 
                // fill: this.colors[this.info.type],
                fill: 'white',
                // shadowBlur: this.hover? 3 : 0,
                cornerRadius: 2,
            }


        },
        hatchedRectConfig(){
            return {
                x: this.x, 
                y: this.y + 3, 
                width: 10, 
                height: 4, 
                // stroke: 'black',
                // strokeWidth: this.selected? 1 : 0 , 
                // strokeWidth:  1  , 
                // fill: this.colors[this.info.type],
                fill: '#999999',
                // shadowBlur: this.hover? 3 : 0,
                // cornerRadius: 2,
            }


        },
    }
}
</script>
