<template>
  <div class="d-flex flex-row flex-wrap">
      <v-card flat class="mr-4 flex-grow-1">
        <v-card-title class="grey lighten-3 font-weight-medium"> <v-icon class="mr-1">mdi-poll</v-icon> Sample Analysis Analytics</v-card-title>
        <v-card-text class="px-1 body-1">
          <v-row dense class="mt-1 px-4" :style="idx % 2 == 1 ? 'background-color:#dddddd60' : ''" v-for="(item, idx) in allStats" :key="'d' + idx">
            <v-col cols="10" class="font-weight-medium py-1">{{ item.name }}</v-col>
            <v-col cols="2">{{ item.value }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    <apexchart type="bar" :options="statusStatsChartOptions" :series="statusStatsChartSeries"></apexchart>
    <apexchart type="pie" :options="decisionStatsChartOptions" :series="decisionStatsChartSeries"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import loadSampleList from "@/mixins/lucidAllAnalysis/loadSampleList.js"

export default {
  name: "LucidAllAnalysisStatistics",
  components: { apexchart: VueApexCharts },
  mixins: [loadSampleList],

  props: {
    sampleId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
  },
  created(){
    this.loadSamples(this.sampleId, this.cohortId)
  },
  computed: {

    allStats() {
      const x = Object.keys(this.statusStats).map((item) => {
        let i = {};
        i["name"] = item;
        i["value"] = this.statusStats[item];
        return i;
      });
      const y = Object.keys(this.decisionStats).map((item) => {
        let i = {};
        i["name"] = item;
        i["value"] = this.decisionStats[item];
        return i;
      });
      // console.log(x.concat(y));
      return x.concat(y);
    },
    statusStatsChartSeries() {
      return [
        {
          data: Object.values(this.statusStats),
        },
      ];
    },
    statusStatsChartOptions() {
      return {
        chart: {
          width: 550,
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Analysis Status",
          align: "center",
          margin: 10,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: 'IBM Plex sans',
            color: "#000000b0",
          },
        },
        colors: ["#0393A8b5"],

        // colors:['red','black','grey','blue'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: Object.keys(this.statusStats),
        },
      };
    },
    decisionStatsChartSeries() {
      return Object.values(this.decisionStats);
    },
    decisionStatsChartOptions() {
      return {
        chart: {
          width: 450,
          height: 350,

          type: "pie",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        colors: ["#97c9da", "#7bb695", "#ec9999", "#ffc671"],
        title: {
          text: "Final Decision",
          align: "center",
          margin: 10,
          floating: false,
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: 'IBM Plex sans',
            color: "#000000b0",
          },
        },
        // colors:['red','black','grey','blue'],
        labels: Object.keys(this.decisionStats),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    decisionStats() {
      let stats = {
        "Not Decided": 0,
        Solved: 0,
        Unsolved: 0,
        "Unsolved with Strong Candidates": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item?.decision?.title || "Not Decided"]++;
      });
      return stats;
    },
    statusStats() {
      let stats = {
        Analyzed: 0,
        "Analysis in Progress": 0,
        "Not Analyzed": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item.hrStatus.msg]++;
      });
      return stats;
    },
    sampleList() {
      return this.samples
      // if (this.samples && this.samples.size > 0) {
      //   return Array.from(this.samples, ([sampleName, info]) => ({ sampleName, info }))
      //     .map((item) => {
      //       item.svDataset = item.info.datasets.find((x) => x.datasetType.name == "SV") || null;
      //       item.snvDataset = item.info.datasets.find((x) => x.datasetType.name == "SNV") || null;
      //       item.hrStatus = this.sampleStatues[item.info.status];
      //       (item.st = item.info.status), (item.decision = item.info.decision);
      //       // console.log(item.info.decision)
      //       return item;
      //     })
      //     // .filter((item) => this.filteredStatuses.indexOf(item.st) >= 0)
      //     // .filter((item) => this.filteredDecisions.indexOf((item.decision?.id || 0).toString()) >= 0);
      // } else {
      //   return [];
      // }
    }
  },
};
</script>